var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      _c(
        "v-container",
        { staticClass: "container--wide" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "planningDialogContainer__header",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "elementSubTitle" },
                    [
                      _c("v-icon", [_vm._v("question_answer")]),
                      _vm._v(" Vragen en Calculatieduur ")
                    ],
                    1
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "planningDialogContainer__close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "py-5": "", "px-3": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs7: "" } },
                        [
                          _c("h2", { staticClass: "elementSubTitle" }, [
                            _vm._v("Vragenlijst")
                          ]),
                          _vm.planningValidation.report
                            ? _c("DynamicForm", {
                                attrs: {
                                  reportId: _vm.planningValidation.report.uuid,
                                  reportType:
                                    _vm.planningValidation.report.type.uuid,
                                  planningQuestions: true,
                                  sections: [6, 110],
                                  appendKeys: _vm.dynamicFormAppendKeys,
                                  hasAutosave: true
                                },
                                on: { questionAnswered: _vm.questionAnswered }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs5: "" } }, [
                        _c(
                          "div",
                          { staticClass: "calcContainer" },
                          [
                            _c("h2", { staticClass: "elementSubTitle" }, [
                              _vm._v("Rekenmodule expertisetijd")
                            ]),
                            _vm.minutes
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "item__list",
                                    attrs: { wrap: "" }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "label",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs3: "" } },
                                              [_vm._v("Type woning")]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs3: "",
                                                  "text-right": ""
                                                }
                                              },
                                              [
                                                _vm._v(" Tijd voor bezoek "),
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v("(minuten)")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs3: "",
                                                  "text-right": ""
                                                }
                                              },
                                              [
                                                _vm._v(" Tijdsduur bezoek "),
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v("(minuten)")
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs3: "",
                                                  "text-right": ""
                                                }
                                              },
                                              [
                                                _vm._v(" Tijd na bezoek "),
                                                _c("br"),
                                                _c("small", [
                                                  _vm._v("(minuten)")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.minutes, function(
                                      minute,
                                      index
                                    ) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: "minute-" + index,
                                          staticClass: "list__item",
                                          class:
                                            index + 1 === _vm.minutes.length
                                              ? "list__item--bold"
                                              : "",
                                          attrs: { xs12: "" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                wrap: "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs3: "" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      minute.name
                                                        ? minute.name
                                                        : "-"
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [_vm._v(_vm._s(minute.before))]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [_vm._v(_vm._s(minute.visit))]
                                              ),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs3: "",
                                                    "text-right": ""
                                                  }
                                                },
                                                [_vm._v(_vm._s(minute.after))]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "duration-total",
                                        attrs: { xs12: "" }
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs3: "" } },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "elementSubTitle"
                                                  },
                                                  [_vm._v("Tijd voor bezoek:")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs2: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fadedText" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("parseHours")(
                                                            _vm
                                                              .planningValidation
                                                              .duration.minutes
                                                              .total.before
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs7: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fadedText" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "parseMinutes"
                                                          )(
                                                            _vm
                                                              .planningValidation
                                                              .duration.minutes
                                                              .total.before
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs3: "" } },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "elementSubTitle"
                                                  },
                                                  [_vm._v("Deze opname duurt:")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs2: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fadedText" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("parseHours")(
                                                            _vm
                                                              .planningValidation
                                                              .duration.minutes
                                                              .total.visit
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs7: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fadedText" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "parseMinutes"
                                                          )(
                                                            _vm
                                                              .planningValidation
                                                              .duration.minutes
                                                              .total.visit
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs3: "" } },
                                              [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "elementSubTitle"
                                                  },
                                                  [_vm._v("Tijd na bezoek:")]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs2: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fadedText" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("parseHours")(
                                                            _vm
                                                              .planningValidation
                                                              .duration.minutes
                                                              .total.after
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs7: "" } },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "fadedText" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f(
                                                            "parseMinutes"
                                                          )(
                                                            _vm
                                                              .planningValidation
                                                              .duration.minutes
                                                              .total.after
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _c(
                              "v-layout",
                              { attrs: { "text-right": "", "mt-3": "" } },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    staticClass: "mt-1",
                                    attrs: {
                                      color: "success",
                                      compact: "true",
                                      icon: "save"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.saveAndClose($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Opslaan ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }