import { RejectReason } from '@/models/RejectReason';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Rpc } from '@/models/Rpc';
import { Report } from '@/models/Report';
import { ReportType } from '@/models/ReportType';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { OpnameVariantLabels } from '@/items/OpnameVariant';

@Component<ReportTypeChangeDialog>({})
export default class ReportTypeChangeDialog extends Vue {
  @Prop()
  protected report!: Report;

  @Prop()
  protected types!: ReportType[];

  @Prop()
  protected type!: string;

  @Prop()
  protected opnameVariant!: string;

  protected isLoading = false;

  protected rejectReasons = [];

  protected selectedRejectReason: RejectReason | null = null;

  protected rejectReasonComment = '';

  protected async mounted() {
    this.isLoading = true;
    await this.fetchRejectReasons();
    this.isLoading = false;
  }

  protected async fetchRejectReasons() {
    this.rejectReasons = await new RejectReason()
      .filter({
        type: 'planning',
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected close() {
    this.$emit('input', false);
  }

  protected get reportTypeName() {
    const foundType = this.types.find((type: ReportType) => `${type?.uuid || ''}` === this.type);

    return foundType?.name;
  }

  protected get opnameVariantName() {
    return OpnameVariantLabels[this.opnameVariant];
  }

  protected get isRejectReasonOther() {
    return this.selectedRejectReason?.name === 'Anders';
  }

  protected async submit() {
    if (! this.type || ! this.selectedRejectReason?.id) {
      return;
    }

    this.isLoading = true;

    const payload = {
      signature: 'planning:convert',
      body: {
        report: this.report.uuid,
        reject_reason: this.selectedRejectReason?.id,
        report_type: this.type,
        opname_variant: this.opnameVariant,
      },
    };

    await new Rpc()
      .rpcPost(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;

    this.close();
    this.$emit('reload');
  }
}
