var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      _c(
        "v-container",
        { staticClass: "container--wide" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "pb-5": "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "planningDialogContainer__header",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "elementSubTitle" },
                    [
                      _c("v-icon", [_vm._v("assignment")]),
                      _vm._v(" Dossier details ")
                    ],
                    1
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "planningDialogContainer__close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              ),
              _c(
                "v-container",
                {
                  staticClass: "reportContainer",
                  attrs: { "mt-4": "", "grid-list-lg": "" }
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { sm12: "", md4: "", lg3: "" } }, [
                        _vm.report
                          ? _c(
                              "div",
                              { staticClass: "elementPanel profile" },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    attrs: { row: "", wrap: "" }
                                  },
                                  [
                                    _c("v-flex", [
                                      _c(
                                        "h1",
                                        { staticClass: "profile__userName" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.report.case_number) +
                                              " "
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _vm.report.is_prio
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "prio-icon"
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "warning"
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                31410644
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Dit is een prio dossier"
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _vm.report.is_special
                                                          ? _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "special-icon"
                                                                },
                                                                on
                                                              ),
                                                              [_vm._v("star")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                false,
                                                3472463914
                                              )
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Dit is een special dossier"
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.report.status
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "statusPill",
                                              class:
                                                "statusBackgroundColor--" +
                                                _vm.getStatusColor(
                                                  _vm.report.status
                                                )
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.report.status.length
                                                      ? _vm.getStatusLabel(
                                                          _vm.report.status
                                                        )
                                                      : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", { attrs: { sm12: "" } }, [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Opname variant")
                                      ]),
                                      _vm.report.opname_variant
                                        ? _c(
                                            "div",
                                            { staticClass: "value disabled" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.report.opname_variant
                                                )
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    !_vm.isVES
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { sm12: "" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "label" },
                                                [_vm._v("Calculatiemodel")]
                                              ),
                                              _vm.report.calculation_model
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "value disabled"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.report
                                                              .calculation_model
                                                              .name
                                                          ) +
                                                          " (" +
                                                          _vm._s(
                                                            _vm.report
                                                              .calculation_model
                                                              .from
                                                          ) +
                                                          ") " +
                                                          _vm._s(
                                                            _vm.getVatText()
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _c("v-flex", { attrs: { sm12: "" } }, [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Naam aanvrager")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "value disabled" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.report.applicant
                                                ? _vm.report.applicant.name
                                                : ""
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("v-flex", { attrs: { sm12: "" } }, [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Adres")
                                      ]),
                                      _vm.report.address
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.report.googleMapUrl(),
                                                target: "_blank"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "value disabled"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .street
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .number
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .number_add
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.report.address
                                                          .postcode
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.report.address.city
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]),
                                    _vm.report.isVesNulmetingReport
                                      ? _c("v-flex", { attrs: { xs12: "" } }, [
                                          _c("div", { staticClass: "label" }, [
                                            _vm._v("Kadastrale aanduiding")
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "value disabled" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.report.printAnswer(
                                                      "ToelichtingKadastraalObject"
                                                    )
                                                      ? _vm.report.printAnswer(
                                                          "ToelichtingKadastraalObject"
                                                        )
                                                      : "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Dossierbeheerder")
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "value disabled" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.report.report_handler
                                                ? _vm.report.report_handler.name
                                                : "-"
                                            )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "chips",
                                                attrs: {
                                                  s12: "",
                                                  "align-content-start": "",
                                                  "align-start": "",
                                                  "justify-start": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [_vm._v("Competenties")]
                                                ),
                                                _vm._l(
                                                  _vm.report.skills,
                                                  function(skill, index) {
                                                    return _c(
                                                      "v-chip",
                                                      {
                                                        key: "skill-" + index,
                                                        style:
                                                          "background-color: " +
                                                          skill.color +
                                                          " !important"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(skill.name)
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticClass: "chips",
                                                attrs: {
                                                  s12: "",
                                                  "align-content-start": "",
                                                  "align-start": "",
                                                  "justify-start": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [_vm._v("Tags")]
                                                ),
                                                _vm._l(
                                                  _vm.report.tags,
                                                  function(tag, index) {
                                                    return _c(
                                                      "v-chip",
                                                      { key: "tag-" + index },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(tag.name)
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { sm12: "", md8: "", lg9: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "elementPanel profile",
                                    attrs: { "mb-4": "" }
                                  },
                                  [
                                    _vm.report
                                      ? _c("ReportAppointment", {
                                          attrs: {
                                            report: _vm.report,
                                            disableEditing: true
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "elementPanel profile",
                                    attrs: { "mb-4": "" }
                                  },
                                  [
                                    _vm.report
                                      ? _c("ReportAppointmentCommunication", {
                                          attrs: { report: _vm.report }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          ),
                          !_vm.isVES
                            ? [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "elementPanel profile",
                                          attrs: { "mb-4": "" }
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v("Historisch schades")
                                          ]),
                                          _vm.report
                                            ? _c("HistoricalDamagePanel", {
                                                attrs: {
                                                  reportId: _vm.report.uuid
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ApprovalBar", {
        attrs: { backFunction: true, backText: "Kies een ander dossier" },
        on: { back: _vm.back },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "success",
                          small: "true",
                          icon: "check_circle"
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.chooseReport($event)
                          }
                        }
                      },
                      [_vm._v(" Dit dossier selecteren ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }