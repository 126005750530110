var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _vm.$store.state.isServiceOrganization
            ? _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                _c("h1", { staticClass: "elementSubTitle" }, [
                  _vm._v(" " + _vm._s(_vm.planningTitle) + " ")
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.isLoading
        ? _c("LoaderCard", { attrs: { type: "spinner--center" } })
        : _vm._e(),
      !_vm.$store.state.isServiceOrganization
        ? _c(
            "v-container",
            {
              staticClass: "planningtool--container",
              attrs: { "grid-list-lg": "" }
            },
            [_vm._v(" Niet beschikbaar ")]
          )
        : _vm._e(),
      _vm.$store.state.isServiceOrganization
        ? [
            !_vm.isLoading
              ? _c(
                  "v-container",
                  {
                    staticClass: "planningtool--container",
                    attrs: { "grid-list-lg": "" }
                  },
                  [
                    _vm.isOpnameVanAfstand
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "availability-warning",
                            attrs: {
                              "align-center": "",
                              "px-2": "",
                              "mb-3": ""
                            }
                          },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "fill-height": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "translate-icon",
                                        attrs: { "mr-3": "" }
                                      },
                                      [_vm._v("warning")]
                                    )
                                  ],
                                  1
                                ),
                                _c("v-flex", [
                                  _c("span", { staticClass: "warning-text" }, [
                                    _vm._v("Je plant nu een Opname op Afstand")
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { class: _vm.hasErrorsOrWarnings ? "xs9" : "xs12" },
                          [
                            _vm.planningValidation &&
                            _vm.planningValidation.is_valid
                              ? _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c("PlanningBlock", {
                                          attrs: {
                                            type: "report",
                                            isCompleted:
                                              _vm.planningValidation.is_valid
                                                .report,
                                            showContent:
                                              _vm.planningValidation.is_valid
                                                .report,
                                            planningValidation:
                                              _vm.planningValidation,
                                            hasReportClick: true,
                                            hasFullScreen: true
                                          },
                                          on: {
                                            fullscreen: function($event) {
                                              return _vm.setFullscreen("report")
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "content",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        staticClass:
                                                          "headerRow",
                                                        attrs: {
                                                          row: "",
                                                          wrap: "",
                                                          "align-center": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "summaryItem summaryItem__report",
                                                            attrs: {
                                                              xs12: "",
                                                              "pa-0": "",
                                                              "align-center": ""
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "assignment"
                                                              )
                                                            ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "subHeader"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .planningValidation
                                                                        .report
                                                                        ? _vm
                                                                            .planningValidation
                                                                            .report
                                                                            .case_number
                                                                        : ""
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "external-link"
                                                              },
                                                              [_vm._v("launch")]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-layout",
                                                      {
                                                        attrs: {
                                                          row: "",
                                                          wrap: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs6: "",
                                                              "pa-0": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                staticClass:
                                                                  "itemRow"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "summaryItem",
                                                                    attrs: {
                                                                      xs12: "",
                                                                      "pa-0": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "person"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .planningValidation
                                                                            .applicantName
                                                                        )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                staticClass:
                                                                  "itemRow"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "summaryItem",
                                                                    attrs: {
                                                                      xs12: "",
                                                                      "pa-0": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mail"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .planningValidation
                                                                            .applicantEmail
                                                                        )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                staticClass:
                                                                  "itemRow"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    staticClass:
                                                                      "summaryItem",
                                                                    attrs: {
                                                                      xs12: "",
                                                                      "pa-0": ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "phone"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .planningValidation
                                                                            .applicantPhone
                                                                        )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs6: "",
                                                              "pa-0": ""
                                                            }
                                                          },
                                                          [
                                                            _vm
                                                              .planningValidation
                                                              .report &&
                                                            _vm
                                                              .planningValidation
                                                              .report.address
                                                              ? _c(
                                                                  "v-layout",
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "flex-starving",
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "picture_as_pdf"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .planningValidation
                                                                                  .report
                                                                                  .type
                                                                                  .name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .planningValidation
                                                              .report &&
                                                            _vm
                                                              .planningValidation
                                                              .report.address
                                                              ? _c(
                                                                  "v-layout",
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "flex-starving",
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "switch_video"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .planningValidation
                                                                                  .report
                                                                                  .opnameVariantName
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm
                                                              .planningValidation
                                                              .report &&
                                                            _vm
                                                              .planningValidation
                                                              .report.address
                                                              ? _c(
                                                                  "v-layout",
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "flex-starving",
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "home_work"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "addressLine"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .report
                                                                                    .address
                                                                                    .street
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .report
                                                                                    .address
                                                                                    .number
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "addressLine addressLine--small"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .report
                                                                                    .address
                                                                                    .postcode
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .report
                                                                                    .address
                                                                                    .city
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.isVES &&
                                                            _vm
                                                              .planningValidation
                                                              .report &&
                                                            _vm.planningValidation.report.printAnswer(
                                                              "ToelichtingKadastraalObject"
                                                            )
                                                              ? _c(
                                                                  "v-layout",
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "flex-starving",
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "holiday_village"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "addressLine"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.planningValidation.report.printAnswer(
                                                                                    "ToelichtingKadastraalObject"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            925191388
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    true
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c("PlanningBlock", {
                                                attrs: {
                                                  type: _vm.datetimeBlockType,
                                                  isCompleted:
                                                    _vm.planningValidation
                                                      .is_valid.datetime,
                                                  showContent:
                                                    _vm.planningValidation
                                                      .is_valid.datetime,
                                                  isZelfopname:
                                                    _vm.isZelfopname &&
                                                    !_vm.isReplanning,
                                                  disabled:
                                                    _vm.isZelfopname &&
                                                    !_vm.isReplanning
                                                },
                                                on: {
                                                  fullscreen: function($event) {
                                                    return _vm.setFullscreen(
                                                      "datetime"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    _vm.planningValidation
                                                      .starts_at &&
                                                    _vm.planningValidation
                                                      .ends_at
                                                      ? {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "settings"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .appointmentType
                                                                                .label
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              false &&
                                                              _vm.planningValidation &&
                                                              _vm
                                                                .planningValidation
                                                                .settings &&
                                                              _vm
                                                                .planningValidation
                                                                .settings
                                                                .has_submission
                                                                ? _c(
                                                                    "v-layout",
                                                                    {
                                                                      staticClass:
                                                                        "itemRow",
                                                                      attrs: {
                                                                        row: "",
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "summaryItem",
                                                                          attrs: {
                                                                            xs12:
                                                                              "",
                                                                            "pa-0":
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "send"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Maak ook een vooropname aan voor de zelfopname app"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "date_range"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "parseDate"
                                                                              )(
                                                                                _vm
                                                                                  .planningValidation
                                                                                  .starts_at
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "access_time"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "parseTime"
                                                                                )(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .starts_at
                                                                                )
                                                                              ) +
                                                                              " - " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "parseTime"
                                                                                )(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .ends_at
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm.planningValidation &&
                                                              _vm
                                                                .planningValidation
                                                                .expert
                                                                ? [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "itemRow",
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "summaryItem",
                                                                            attrs: {
                                                                              xs12:
                                                                                "",
                                                                              "pa-0":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "work"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.planningValidation &&
                                                                                        _vm
                                                                                          .planningValidation
                                                                                          .organization
                                                                                        ? _vm
                                                                                            .planningValidation
                                                                                            .organization
                                                                                            .name
                                                                                        : ""
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "itemRow",
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "summaryItem",
                                                                            attrs: {
                                                                              xs12:
                                                                                "",
                                                                              "pa-0":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "person"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.planningValidation &&
                                                                                        _vm
                                                                                          .planningValidation
                                                                                          .expert
                                                                                        ? _vm
                                                                                            .planningValidation
                                                                                            .expert
                                                                                            .name
                                                                                        : ""
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                : _vm._e(),
                                                              _vm.planningValidation &&
                                                              _vm
                                                                .planningValidation
                                                                .user
                                                                ? [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "itemRow",
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "summaryItem",
                                                                            attrs: {
                                                                              xs12:
                                                                                "",
                                                                              "pa-0":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "person"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.planningValidation &&
                                                                                        _vm
                                                                                          .planningValidation
                                                                                          .user
                                                                                        ? _vm
                                                                                            .planningValidation
                                                                                            .user
                                                                                            .name
                                                                                        : ""
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                : _vm._e()
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      : null
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          !_vm.isVES
                                            ? [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs4: "" } },
                                                  [
                                                    _c("PlanningBlock", {
                                                      attrs: {
                                                        type: "appointment",
                                                        isCompleted: _vm.isImmaterial
                                                          ? _vm
                                                              .planningValidation
                                                              .is_valid.address
                                                          : _vm
                                                              .planningValidation
                                                              .is_valid.report,
                                                        showContent:
                                                          _vm.planningValidation
                                                            .is_valid.report,
                                                        isZelfopname:
                                                          _vm.isZelfopname &&
                                                          !_vm.isReplanning,
                                                        disabled:
                                                          (_vm.isZelfopname &&
                                                            !_vm.isReplanning) ||
                                                          !_vm.isManager
                                                      },
                                                      on: {
                                                        fullscreen: function(
                                                          $event
                                                        ) {
                                                          return _vm.setFullscreen(
                                                            "appointment"
                                                          )
                                                        }
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          true
                                                            ? {
                                                                key: "content",
                                                                fn: function() {
                                                                  return [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "itemRow",
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm.planningValidation &&
                                                                        _vm
                                                                          .planningValidation
                                                                          .settings &&
                                                                        !_vm
                                                                          .planningValidation
                                                                          .settings
                                                                          .confirm_email_to_applicant
                                                                          ? [
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  staticClass:
                                                                                    "summaryItem",
                                                                                  attrs: {
                                                                                    xs12:
                                                                                      "",
                                                                                    "pa-0":
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "cancel_schedule_send"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Geen uitnodiging versturen aan de aanvrager"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          : [
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  staticClass:
                                                                                    "summaryItem",
                                                                                  attrs: {
                                                                                    xs12:
                                                                                      "",
                                                                                    "pa-0":
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "send"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Uitnodiging versturen aan aanvrager"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                      ],
                                                                      2
                                                                    ),
                                                                    _vm
                                                                      .planningValidation
                                                                      .address &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .address
                                                                      .street
                                                                      ? _c(
                                                                          "v-layout",
                                                                          {
                                                                            staticClass:
                                                                              "itemRow",
                                                                            attrs: {
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs12:
                                                                                    "",
                                                                                  "pa-0":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-layout",
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "flex-starving",
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "home_work"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "addressLine"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .planningValidation
                                                                                                    .address
                                                                                                    .street
                                                                                                ) +
                                                                                                " " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .planningValidation
                                                                                                    .address
                                                                                                    .number
                                                                                                ) +
                                                                                                " "
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "addressLine addressLine--small"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .planningValidation
                                                                                                    .address
                                                                                                    .postcode
                                                                                                ) +
                                                                                                " " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .planningValidation
                                                                                                    .address
                                                                                                    .city
                                                                                                ) +
                                                                                                " "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                },
                                                                proxy: true
                                                              }
                                                            : null
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e()
                                        ]
                                      : [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c("PlanningBlock", {
                                                attrs: {
                                                  type: "datetime",
                                                  isCompleted:
                                                    _vm.planningValidation
                                                      .is_valid.datetime,
                                                  showContent:
                                                    _vm.planningValidation
                                                      .is_valid.datetime,
                                                  isZelfopname:
                                                    _vm.isZelfopname &&
                                                    !_vm.isReplanning,
                                                  disabled:
                                                    _vm.isZelfopname &&
                                                    !_vm.isReplanning
                                                },
                                                on: {
                                                  fullscreen: function($event) {
                                                    return _vm.setFullscreen(
                                                      "datetime"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    _vm.planningValidation
                                                      .starts_at &&
                                                    _vm.planningValidation
                                                      .ends_at
                                                      ? {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "date_range"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "parseDate"
                                                                              )(
                                                                                _vm
                                                                                  .planningValidation
                                                                                  .starts_at
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "access_time"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "parseTime"
                                                                                )(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .starts_at
                                                                                )
                                                                              ) +
                                                                              " - " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "parseTime"
                                                                                )(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .ends_at
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      : null
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c("PlanningBlock", {
                                                attrs: {
                                                  type: "expert",
                                                  isCompleted:
                                                    _vm.planningValidation
                                                      .is_valid.expert,
                                                  showContent:
                                                    _vm.planningValidation
                                                      .is_valid.expert,
                                                  isZelfopname:
                                                    _vm.isZelfopname &&
                                                    !_vm.isReplanning,
                                                  disabled:
                                                    _vm.isZelfopname &&
                                                    !_vm.isReplanning
                                                },
                                                on: {
                                                  fullscreen: function($event) {
                                                    return _vm.setFullscreen(
                                                      "expert"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    _vm.planningValidation &&
                                                    _vm.planningValidation
                                                      .expert &&
                                                    _vm.planningValidation
                                                      .report &&
                                                    (!_vm.isZelfopname ||
                                                      _vm.isReplanning)
                                                      ? {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "work"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.planningValidation &&
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .organization
                                                                                  ? _vm
                                                                                      .planningValidation
                                                                                      .organization
                                                                                      .name
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "person"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.planningValidation &&
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .expert
                                                                                  ? _vm
                                                                                      .planningValidation
                                                                                      .expert
                                                                                      .name
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      : null
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                    !_vm.isImmaterial && !_vm.isVES
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c("PlanningBlock", {
                                                attrs: {
                                                  type: "ves_status",
                                                  planningValidation:
                                                    _vm.planningValidation,
                                                  isCompleted:
                                                    _vm.planningValidation
                                                      .is_valid.ves_status,
                                                  disabled:
                                                    _vm.planningValidation
                                                      .is_valid.ves_status
                                                },
                                                on: {
                                                  fullscreen: function($event) {
                                                    return _vm.setFullscreen(
                                                      "ves_status"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "content",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              staticClass:
                                                                "itemRow",
                                                              attrs: {
                                                                row: "",
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "summaryItem",
                                                                  attrs: {
                                                                    xs12: "",
                                                                    "pa-0": ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "work"
                                                                    )
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.planningValidation &&
                                                                            _vm
                                                                              .planningValidation
                                                                              .organization &&
                                                                            _vm
                                                                              .planningValidation
                                                                              .organization
                                                                              .name
                                                                            ? _vm
                                                                                .planningValidation
                                                                                .organization
                                                                                .name
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3258262817
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    !_vm.isVES
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c("PlanningBlock", {
                                                attrs: {
                                                  type: "zaakbegeleider",
                                                  isCompleted:
                                                    _vm.planningValidation
                                                      .is_valid.mediator,
                                                  showContent:
                                                    _vm.planningValidation
                                                      .report.isBezwaarReport ||
                                                    _vm.planningValidation
                                                      .mediator_starts_at ||
                                                    (_vm.planningValidation
                                                      .mediator &&
                                                      _vm.planningValidation
                                                        .mediator.uuid) ||
                                                    _vm.planningValidation
                                                      .report.isBezwaarReport,
                                                  disabled:
                                                    _vm.planningValidation
                                                      .report.isBezwaarReport
                                                },
                                                on: {
                                                  fullscreen: function($event) {
                                                    return _vm.setFullscreen(
                                                      "zaakbegeleider"
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "content",
                                                      fn: function() {
                                                        return [
                                                          _vm.planningValidation
                                                            .report
                                                            .isBezwaarReport
                                                            ? [
                                                                _vm._v(
                                                                  " Niet van toepassing bij Bezwaar "
                                                                )
                                                              ]
                                                            : [
                                                                !_vm
                                                                  .planningValidation
                                                                  .without_mediator
                                                                  ? _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "itemRow",
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "summaryItem",
                                                                            attrs: {
                                                                              xs12:
                                                                                "",
                                                                              "pa-0":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              [
                                                                                _vm._v(
                                                                                  "settings"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .mediatorAppointmentType
                                                                                      .label
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    staticClass:
                                                                      "itemRow",
                                                                    attrs: {
                                                                      row: "",
                                                                      wrap: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "summaryItem",
                                                                        attrs: {
                                                                          xs12:
                                                                            "",
                                                                          "pa-0":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "person"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .planningValidation
                                                                                  .mediator
                                                                                  .name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                !_vm
                                                                  .planningValidation
                                                                  .without_mediator
                                                                  ? [
                                                                      _c(
                                                                        "v-layout",
                                                                        {
                                                                          staticClass:
                                                                            "itemRow",
                                                                          attrs: {
                                                                            row:
                                                                              "",
                                                                            wrap:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              staticClass:
                                                                                "summaryItem",
                                                                              attrs: {
                                                                                xs12:
                                                                                  "",
                                                                                "pa-0":
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "date_range"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm._f(
                                                                                        "parseDate"
                                                                                      )(
                                                                                        _vm.planningValidation &&
                                                                                          _vm
                                                                                            .planningValidation
                                                                                            .mediator_starts_at
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-layout",
                                                                        {
                                                                          staticClass:
                                                                            "itemRow",
                                                                          attrs: {
                                                                            row:
                                                                              "",
                                                                            wrap:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              staticClass:
                                                                                "summaryItem",
                                                                              attrs: {
                                                                                xs12:
                                                                                  "",
                                                                                "pa-0":
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "access_time"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "parseTime"
                                                                                        )(
                                                                                          _vm.planningValidation &&
                                                                                            _vm
                                                                                              .planningValidation
                                                                                              .mediator_starts_at
                                                                                        )
                                                                                      ) +
                                                                                      " - " +
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "parseTime"
                                                                                        )(
                                                                                          _vm.planningValidation &&
                                                                                            _vm
                                                                                              .planningValidation
                                                                                              .mediator_ends_at
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  : _vm._e()
                                                              ]
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  185228705
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    !_vm.isImmaterial
                                      ? [
                                          !_vm.isReplanning
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("PlanningBlock", {
                                                    attrs: {
                                                      type: "questions",
                                                      planningValidation:
                                                        _vm.planningValidation,
                                                      isCompleted:
                                                        _vm.planningValidation
                                                          .is_valid.questions,
                                                      showContent:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      disabled:
                                                        !_vm.planningValidation ||
                                                        !_vm.planningValidation
                                                          .is_valid ||
                                                        !_vm.planningValidation
                                                          .is_valid.report ||
                                                        _vm.planningValidation
                                                          .report
                                                          .isBezwaarReport
                                                    },
                                                    on: {
                                                      fullscreen: function(
                                                        $event
                                                      ) {
                                                        return _vm.setFullscreen(
                                                          "questions"
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _vm
                                                                .planningValidation
                                                                .report
                                                                .isBezwaarReport
                                                                ? [
                                                                    _vm._v(
                                                                      " Niet van toepassing bij Bezwaar "
                                                                    )
                                                                  ]
                                                                : [
                                                                    _vm.planningValidation &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report
                                                                      ? _c(
                                                                          "v-layout",
                                                                          {
                                                                            staticClass:
                                                                              "itemRow",
                                                                            attrs: {
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                staticClass:
                                                                                  "summaryItem",
                                                                                attrs: {
                                                                                  xs12:
                                                                                    "",
                                                                                  "pa-0":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "access_time"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm._f(
                                                                                          "parseHours"
                                                                                        )(
                                                                                          _vm
                                                                                            .planningValidation
                                                                                            .totalHours
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.planningValidation &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report
                                                                      .type &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report
                                                                      .type
                                                                      .length
                                                                      ? _c(
                                                                          "v-layout",
                                                                          {
                                                                            staticClass:
                                                                              "itemRow",
                                                                            attrs: {
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                staticClass:
                                                                                  "summaryItem",
                                                                                attrs: {
                                                                                  xs12:
                                                                                    "",
                                                                                  "pa-0":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "assignment"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm
                                                                                          .planningValidation
                                                                                          .report
                                                                                          .type
                                                                                          .name
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.planningValidation &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report
                                                                      ? _c(
                                                                          "v-layout",
                                                                          {
                                                                            staticClass:
                                                                              "itemRow",
                                                                            attrs: {
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                staticClass:
                                                                                  "summaryItem",
                                                                                attrs: {
                                                                                  xs12:
                                                                                    "",
                                                                                  "pa-0":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "progressLabel"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " vragen beantwoord "
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "progressLabel--accent",
                                                                                        class: {
                                                                                          completed:
                                                                                            _vm
                                                                                              .planningValidation
                                                                                              .questions
                                                                                              .complete
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .planningValidation
                                                                                                .questions
                                                                                                .answered
                                                                                            ) +
                                                                                            "/" +
                                                                                            _vm._s(
                                                                                              _vm
                                                                                                .planningValidation
                                                                                                .questions
                                                                                                .total
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-progress-linear",
                                                                                  {
                                                                                    staticClass:
                                                                                      "progressBar",
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .planningValidation
                                                                                          .questionProgress,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.planningValidation,
                                                                                          "questionProgress",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "planningValidation.questionProgress"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.planningValidation &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report
                                                                      .skills &&
                                                                    _vm
                                                                      .planningValidation
                                                                      .report
                                                                      .skills
                                                                      .length
                                                                      ? _c(
                                                                          "v-layout",
                                                                          {
                                                                            staticClass:
                                                                              "itemRow",
                                                                            attrs: {
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                staticClass:
                                                                                  "summaryItem chips",
                                                                                attrs: {
                                                                                  xs12:
                                                                                    "",
                                                                                  "pa-0":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "label"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._l(
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .report
                                                                                    .skills,
                                                                                  function(
                                                                                    skill,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-chip",
                                                                                      {
                                                                                        key:
                                                                                          "skill-" +
                                                                                          index,
                                                                                        style:
                                                                                          "background-color: " +
                                                                                          skill.color +
                                                                                          " !important"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                skill.name
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                )
                                                                              ],
                                                                              2
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2529236386
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.isReplanning
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs8: "" } },
                                                [
                                                  _c("PlanningBlock", {
                                                    attrs: {
                                                      type: "uitvraag",
                                                      topRightIcon: "schedule",
                                                      isCompleted:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      showContent:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      hasFullScreen: false,
                                                      topRightLabel:
                                                        _vm.planningValidation.report.printAnswer(
                                                          "HerplannenTijdsduur"
                                                        ) + " uur"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _vm.planningValidation &&
                                                              _vm
                                                                .planningValidation
                                                                .report
                                                                ? [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        staticClass:
                                                                          "itemRow fill-height",
                                                                        attrs: {
                                                                          row:
                                                                            "",
                                                                          wrap:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "summaryItem border--right",
                                                                            attrs: {
                                                                              xs6:
                                                                                "",
                                                                              "pa-0":
                                                                                "",
                                                                              "pr-3":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block font-weight-bold"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Omschrijving van de schade"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.planningValidation.report.printAnswer(
                                                                                      "HerplannenAanleiding"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            staticClass:
                                                                              "summaryItem border--left",
                                                                            attrs: {
                                                                              xs6:
                                                                                "",
                                                                              "pa-0":
                                                                                "",
                                                                              "pl-3":
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "d-block font-weight-bold"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "Wat er benodigd is"
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.planningValidation.report.printAnswer(
                                                                                      "HerplannenBenodigd"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                : _vm._e()
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      311652793
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          (_vm.isManager || _vm.isZelfopname) &&
                                          !_vm.isReplanning &&
                                          !_vm.isVES
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("PlanningBlock", {
                                                    attrs: {
                                                      type: "zelfopname",
                                                      isCompleted:
                                                        _vm.planningValidation
                                                          .is_valid.zelfopname,
                                                      showContent:
                                                        _vm.planningValidation
                                                          .is_valid.zelfopname,
                                                      isZelfopname:
                                                        _vm.isZelfopname &&
                                                        !_vm.isReplanning,
                                                      isOpnameVanAfstand:
                                                        _vm.isOpnameVanAfstand
                                                    },
                                                    on: {
                                                      fullscreen: function(
                                                        $event
                                                      ) {
                                                        return _vm.setFullscreen(
                                                          "zelfopname"
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "itemRow",
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      staticClass:
                                                                        "summaryItem",
                                                                      attrs: {
                                                                        xs12:
                                                                          "",
                                                                        "pa-0":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "work"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.planningValidation &&
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .organization &&
                                                                                  _vm
                                                                                    .planningValidation
                                                                                    .organization
                                                                                    .name
                                                                                  ? _vm
                                                                                      .planningValidation
                                                                                      .organization
                                                                                      .name
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      3258262817
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isVES
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("PlanningBlock", {
                                                    attrs: {
                                                      type: "area",
                                                      isCompleted:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      showContent:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      disabled: false
                                                    },
                                                    on: {
                                                      fullscreen: function(
                                                        $event
                                                      ) {
                                                        return _vm.setFullscreen(
                                                          "area"
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _vm.reportsInArea
                                                                .length
                                                                ? [
                                                                    _vm._l(
                                                                      _vm.reportsInArea.slice(
                                                                        0,
                                                                        3
                                                                      ),
                                                                      function(
                                                                        report
                                                                      ) {
                                                                        return _c(
                                                                          "v-layout",
                                                                          {
                                                                            key:
                                                                              report.id,
                                                                            staticClass:
                                                                              "areaReports",
                                                                            attrs: {
                                                                              "pa-0":
                                                                                "",
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  "px-2":
                                                                                    "",
                                                                                  xs4:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    attrs: {
                                                                                      row:
                                                                                        "",
                                                                                      wrap:
                                                                                        "",
                                                                                      "pb-1":
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "areaReports__label",
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "dossiernummer"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            report.case_number
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    attrs: {
                                                                                      row:
                                                                                        "",
                                                                                      wrap:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "areaReports__label",
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "bureau"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            report.organization
                                                                                              ? report
                                                                                                  .organization
                                                                                                  .name
                                                                                              : "-"
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  "px-2":
                                                                                    "",
                                                                                  xs4:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    attrs: {
                                                                                      row:
                                                                                        "",
                                                                                      wrap:
                                                                                        "",
                                                                                      "pb-1":
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "areaReports__label",
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "aanvrager"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            report.applicant
                                                                                              ? report
                                                                                                  .applicant
                                                                                                  .name
                                                                                              : "-"
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    attrs: {
                                                                                      row:
                                                                                        "",
                                                                                      wrap:
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "areaReports__label",
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "dekundige"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              report.organization_expert
                                                                                                ? report
                                                                                                    .organization_expert
                                                                                                    .name
                                                                                                : "-"
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  "px-2":
                                                                                    "",
                                                                                  xs4:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-layout",
                                                                                  {
                                                                                    attrs: {
                                                                                      row:
                                                                                        "",
                                                                                      wrap:
                                                                                        "",
                                                                                      "pb-1":
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        staticClass:
                                                                                          "areaReports__label",
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "indieningsdatum"
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          "pa-0":
                                                                                            "",
                                                                                          xs12:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "dateFormat"
                                                                                            )(
                                                                                              report.applicant_submitted_at
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                    _vm
                                                                      .reportsInArea
                                                                      .length
                                                                      ? _c(
                                                                          "v-layout",
                                                                          {
                                                                            staticClass:
                                                                              "areaReports",
                                                                            attrs: {
                                                                              "pa-0":
                                                                                "",
                                                                              row:
                                                                                "",
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "area-total"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " totaal: " +
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .reportsInArea
                                                                                            .length
                                                                                        ) +
                                                                                        " " +
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .reportsInArea
                                                                                            .length >
                                                                                            1
                                                                                            ? "dossiers"
                                                                                            : "dossier"
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                : [
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "Er zijn geen andere dossiers op deze postcode"
                                                                      )
                                                                    ])
                                                                  ]
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4082219309
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c("PlanningBlock", {
                                                attrs: {
                                                  type: _vm.isVES
                                                    ? "opnemer_departure_address"
                                                    : "departure_address",
                                                  isCompleted:
                                                    _vm.planningValidation
                                                      .is_valid.report,
                                                  showContent:
                                                    _vm.planningValidation
                                                      .is_valid.report,
                                                  disabled: false,
                                                  hasFullScreen: false
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "content",
                                                      fn: function() {
                                                        return [
                                                          _vm.departureAddress &&
                                                          _vm.destinationAddress
                                                            ? _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "googleMaps__container",
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "iframe",
                                                                        {
                                                                          staticStyle: {
                                                                            border:
                                                                              "0"
                                                                          },
                                                                          attrs: {
                                                                            width:
                                                                              "100%",
                                                                            height:
                                                                              "100%",
                                                                            frameborder:
                                                                              "0",
                                                                            src:
                                                                              "https://www.google.com/maps/embed/v1/directions?key=AIzaSyAk2uRjYIuRK1CeSfWHbCQ_czxbGfZi3-U&origin=" +
                                                                              _vm.departureAddress +
                                                                              "&destination=" +
                                                                              _vm.destinationAddress,
                                                                            allowfullscreen:
                                                                              ""
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  479995440
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          !_vm.isVES
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs4: "" } },
                                                [
                                                  _c("PlanningBlock", {
                                                    attrs: {
                                                      type:
                                                        "mediator_departure_address",
                                                      isCompleted:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      showContent:
                                                        _vm.planningValidation
                                                          .is_valid.report,
                                                      disabled: false,
                                                      hasFullScreen: false
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "content",
                                                          fn: function() {
                                                            return [
                                                              _vm.mediatorDepartureAddress &&
                                                              _vm.destinationAddress
                                                                ? _c(
                                                                    "v-layout",
                                                                    {
                                                                      staticClass:
                                                                        "googleMaps__container",
                                                                      attrs: {
                                                                        wrap: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs12:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "iframe",
                                                                            {
                                                                              staticStyle: {
                                                                                border:
                                                                                  "0"
                                                                              },
                                                                              attrs: {
                                                                                width:
                                                                                  "100%",
                                                                                height:
                                                                                  "100%",
                                                                                frameborder:
                                                                                  "0",
                                                                                src:
                                                                                  "https://www.google.com/maps/embed/v1/directions?key=AIzaSyAk2uRjYIuRK1CeSfWHbCQ_czxbGfZi3-U&origin=" +
                                                                                  _vm.mediatorDepartureAddress +
                                                                                  "&destination=" +
                                                                                  _vm.destinationAddress,
                                                                                allowfullscreen:
                                                                                  ""
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      894673328
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.planningValidation
                          ? _c(
                              "v-flex",
                              { attrs: { xs3: "", "mt-2": "" } },
                              [
                                _vm.planningValidation.hasErrors
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass: "elementPanel",
                                        attrs: { "pa-2": "", wrap: "" }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "item__list",
                                                attrs: { wrap: "" }
                                              },
                                              _vm._l(
                                                _vm.planningValidation.errors,
                                                function(error, index) {
                                                  return _c(
                                                    "v-flex",
                                                    {
                                                      key: "error-" + index,
                                                      staticClass: "list__item",
                                                      attrs: { xs12: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            wrap: "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "repairValidations",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "customRepairIndicator"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "custom-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "error"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(error)
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.planningValidation.hasWarnings
                                  ? _c(
                                      "v-layout",
                                      {
                                        staticClass: "elementPanel",
                                        attrs: {
                                          "pa-2": "",
                                          wrap: "",
                                          "mt-2": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                staticClass: "item__list",
                                                attrs: { wrap: "" }
                                              },
                                              _vm._l(
                                                _vm.planningValidation.warnings,
                                                function(warning, index) {
                                                  return _c(
                                                    "v-flex",
                                                    {
                                                      key: "warning-" + index,
                                                      staticClass: "list__item",
                                                      attrs: { xs12: "" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          attrs: {
                                                            wrap: "",
                                                            "align-center": ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "repairValidations",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "customRepairIndicator"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "custom"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "warning"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    warning
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("ApprovalBar", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "actionContainer actionContainer__planningtool"
                          },
                          [
                            _vm.planningValidation &&
                            _vm.planningValidation.is_valid
                              ? [
                                  !_vm.isVES &&
                                  _vm.planningValidation.report &&
                                  _vm.planningValidation.report.uuid
                                    ? _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            color: "alert",
                                            small: "true",
                                            disabled: false,
                                            icon: "event_busy"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.isShowingOnHoldReasonDialog = true
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Nog niet in te plannen")
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "MiButton",
                                    {
                                      staticClass: "mr-4 left",
                                      attrs: {
                                        color: "warning",
                                        small: "true",
                                        icon: "restore_page"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.resetReservation($event)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("Reset planning")])]
                                  ),
                                  !_vm.isReplanning || _vm.isVES
                                    ? [
                                        _vm.planningValidation.report &&
                                        _vm.planningValidation.report.uuid
                                          ? _c(
                                              "MiButton",
                                              {
                                                staticClass: "mr-4",
                                                attrs: {
                                                  color: "alert",
                                                  small: "true",
                                                  disabled: false,
                                                  icon: "phone_disabled"
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    _vm.isShowingNoAppointmentdialog = true
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Geen afspraak kunnen plannen"
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  !_vm.isVES &&
                                  !_vm.planningValidation.is_valid.ves_status
                                    ? _c(
                                        "MiButton",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            color: "alert",
                                            small: "true",
                                            icon: "edit_off"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.isShowingExcludeVesDialog = true
                                            }
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Uitsluiten van VES")
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "success",
                                        small: "true",
                                        disabled:
                                          _vm.isSavingAppointment ||
                                          !_vm.canSaveAppointment,
                                        icon: _vm.isSavingAppointment
                                          ? ""
                                          : "check_circle"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.planAppointment()
                                        }
                                      }
                                    },
                                    [
                                      _vm.isSavingAppointment
                                        ? _c("LoaderCard", {
                                            staticClass: "spinner--inline",
                                            attrs: { type: "spinner--small" }
                                          })
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isSavingAppointment
                                                ? "Bezig met opslaan afpraak"
                                                : "Afspraak inplannen " +
                                                    (_vm.isImmaterial
                                                      ? "immaterieel"
                                                      : "")
                                            ) +
                                            " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2062873415
              )
            })
          ]
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["report"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "report", $$v)
            },
            expression: "dialogFullscreenMap['report']"
          }
        },
        [
          _vm.dialogFullscreenMap["report"]
            ? _c("PlanningReportDialog", {
                attrs: {
                  isImmaterial: _vm.isImmaterial,
                  isVES: _vm.isVES,
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["report"] = false
                  },
                  itemSelected: _vm.handleReportSelected
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["datetime"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "datetime", $$v)
            },
            expression: "dialogFullscreenMap['datetime']"
          }
        },
        [
          _vm.dialogFullscreenMap["datetime"]
            ? _c("PlanningExpertDialog", {
                attrs: {
                  isExpert: !_vm.isImmaterial,
                  isImmaterial: _vm.isImmaterial,
                  isVES: _vm.isVES,
                  isVervolgopname: _vm.isVervolgopname,
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations,
                  userType: _vm.isImmaterial
                    ? _vm.UserRole.APPEALS_COMMITTEE
                    : undefined
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["datetime"] = false
                  },
                  validatePlanning: _vm.validatePlanning
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["appointment"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "appointment", $$v)
            },
            expression: "dialogFullscreenMap['appointment']"
          }
        },
        [
          _vm.dialogFullscreenMap["appointment"]
            ? _c("PlanningAppointmentDialog", {
                attrs: {
                  isImmaterial: _vm.isImmaterial,
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["appointment"] = false
                  },
                  validatePlanning: _vm.validatePlanning,
                  questionAnswered: _vm.answeredDebounce
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["expert"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "expert", $$v)
            },
            expression: "dialogFullscreenMap['expert']"
          }
        },
        [
          _vm.dialogFullscreenMap["expert"]
            ? _c("PlanningExpertDialog", {
                attrs: {
                  isImmaterial: _vm.isImmaterial,
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations,
                  userType: _vm.UserRole.EXPERT
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["expert"] = false
                  },
                  validatePlanning: _vm.validatePlanning
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["zaakbegeleider"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "zaakbegeleider", $$v)
            },
            expression: "dialogFullscreenMap['zaakbegeleider']"
          }
        },
        [
          _vm.dialogFullscreenMap["zaakbegeleider"]
            ? _c("PlanningExpertDialog", {
                attrs: {
                  isZaakbegeleider: true,
                  planningValidation: _vm.planningValidation,
                  isImmaterial: _vm.isImmaterial,
                  reservations: _vm.reservations,
                  userType: _vm.UserRole.CASE_MEDIATOR
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["zaakbegeleider"] = false
                  },
                  validatePlanning: _vm.validatePlanning
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["questions"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "questions", $$v)
            },
            expression: "dialogFullscreenMap['questions']"
          }
        },
        [
          _vm.dialogFullscreenMap["questions"]
            ? _c("PlanningQuestionsDialog", {
                attrs: {
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["questions"] = false
                  },
                  validatePlanning: _vm.validatePlanning,
                  questionAnswered: _vm.answeredDebounce
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["ves_status"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "ves_status", $$v)
            },
            expression: "dialogFullscreenMap['ves_status']"
          }
        },
        [
          _vm.dialogFullscreenMap["ves_status"]
            ? _c("PlanningVesStatusDialog", {
                attrs: {
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["ves_status"] = false
                  },
                  validatePlanning: _vm.validatePlanning,
                  vesUpdatedToInterested: _vm.handleVesUpdatedToInterested
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["zelfopname"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "zelfopname", $$v)
            },
            expression: "dialogFullscreenMap['zelfopname']"
          }
        },
        [
          _vm.dialogFullscreenMap["zelfopname"]
            ? _c("PlanningZelfopnameDialog", {
                attrs: {
                  planningValidation: _vm.planningValidation,
                  reservations: _vm.reservations,
                  isOpnameVanAfstand: _vm.isOpnameVanAfstand
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["zelfopname"] = false
                  },
                  validatePlanning: _vm.validatePlanning
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "fullscreen-planning-dialog",
            fullscreen: "",
            "hide-overlay": "",
            transition: "dialog-bottom-transition"
          },
          model: {
            value: _vm.dialogFullscreenMap["area"],
            callback: function($$v) {
              _vm.$set(_vm.dialogFullscreenMap, "area", $$v)
            },
            expression: "dialogFullscreenMap['area']"
          }
        },
        [
          _vm.dialogFullscreenMap["area"]
            ? _c("PlanningReportAreaDialog", {
                attrs: {
                  reportPostcode: _vm.planningValidation.report.address.postcode
                },
                on: {
                  closeDialog: function($event) {
                    _vm.dialogFullscreenMap["area"] = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.isShowingOnHoldReasonDialog,
            callback: function($$v) {
              _vm.isShowingOnHoldReasonDialog = $$v
            },
            expression: "isShowingOnHoldReasonDialog"
          }
        },
        [
          _vm.isShowingOnHoldReasonDialog
            ? _c("PlanningOnHoldReasonDialog", {
                attrs: { report: _vm.planningValidation.report },
                on: { "on-hold-reason-saved": _vm.onConfirmOnHoldReason },
                model: {
                  value: _vm.isShowingOnHoldReasonDialog,
                  callback: function($$v) {
                    _vm.isShowingOnHoldReasonDialog = $$v
                  },
                  expression: "isShowingOnHoldReasonDialog"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.isShowingNoAppointmentdialog,
            callback: function($$v) {
              _vm.isShowingNoAppointmentdialog = $$v
            },
            expression: "isShowingNoAppointmentdialog"
          }
        },
        [
          _vm.isShowingNoAppointmentdialog
            ? _c("PlanningNoAppointmentDialog", {
                attrs: {
                  report: _vm.planningValidation.report,
                  signature: "appointment:not-possible",
                  reasons: _vm.noAppointmentReasons
                },
                on: { noAppointmentConfirm: _vm.onConfirmNoAppointment },
                model: {
                  value: _vm.isShowingNoAppointmentdialog,
                  callback: function($$v) {
                    _vm.isShowingNoAppointmentdialog = $$v
                  },
                  expression: "isShowingNoAppointmentdialog"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", persistent: "" },
          model: {
            value: _vm.isShowingExcludeVesDialog,
            callback: function($$v) {
              _vm.isShowingExcludeVesDialog = $$v
            },
            expression: "isShowingExcludeVesDialog"
          }
        },
        [
          _vm.isShowingExcludeVesDialog
            ? _c("ExcludeVesDialog", {
                attrs: { planningValidation: _vm.planningValidation },
                on: {
                  onCompleted: function($event) {
                    return _vm.validatePlanning($event)
                  }
                },
                model: {
                  value: _vm.isShowingExcludeVesDialog,
                  callback: function($$v) {
                    _vm.isShowingExcludeVesDialog = $$v
                  },
                  expression: "isShowingExcludeVesDialog"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.hasNetworkError
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700px", persistent: "" },
              model: {
                value: _vm.hasNetworkError,
                callback: function($$v) {
                  _vm.hasNetworkError = $$v
                },
                expression: "hasNetworkError"
              }
            },
            [
              _c("PlanningErrorDialog", {
                attrs: { error: _vm.error },
                on: {
                  close: function($event) {
                    _vm.hasNetworkError = false
                  }
                },
                model: {
                  value: _vm.hasNetworkError,
                  callback: function($$v) {
                    _vm.hasNetworkError = $$v
                  },
                  expression: "hasNetworkError"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }