var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      _c(
        "v-container",
        { staticClass: "container--wide" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "planningDialogContainer__header",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "elementSubTitle" },
                    [
                      _c("v-icon", [_vm._v("date_range")]),
                      _vm._v(" Datum en Tijd ")
                    ],
                    1
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "planningDialogContainer__close",
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.isZaakbegeleider
            ? _c(
                "v-layout",
                { attrs: { wrap: "", "mt-5": "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "slider-color": "secondary" },
                          model: {
                            value: _vm.activeOrganization,
                            callback: function($$v) {
                              _vm.activeOrganization = $$v
                            },
                            expression: "activeOrganization"
                          }
                        },
                        [
                          _vm._l(_vm.organizations, function(
                            organization,
                            index
                          ) {
                            return [
                              _c(
                                "v-tab",
                                {
                                  key: "planning-tab-" + index,
                                  staticClass: "tab__filled",
                                  class: {
                                    active:
                                      _vm.activeOrganization === organization.id
                                  },
                                  attrs: { href: "#" + organization.id },
                                  on: {
                                    click: function($event) {
                                      _vm.isLoading = true
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(organization.name) + " ")]
                              )
                            ]
                          }),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.activeOrganization,
                                callback: function($$v) {
                                  _vm.activeOrganization = $$v
                                },
                                expression: "activeOrganization"
                              }
                            },
                            [
                              _vm._l(_vm.organizations, function(organization) {
                                return [
                                  _c(
                                    "v-tab-item",
                                    {
                                      key:
                                        "planning-tab-content-" +
                                        organization.id,
                                      attrs: {
                                        lazy: true,
                                        transition: false,
                                        "reverse-transition": false,
                                        value: organization.id
                                      }
                                    },
                                    [
                                      _vm.activeOrganization === organization.id
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass:
                                                "elementPanel--shadowed",
                                              attrs: {
                                                wrap: "",
                                                "mb-2": "",
                                                "pa-3": "",
                                                "px-3": "",
                                                "justify-start": ""
                                              }
                                            },
                                            [
                                              _c("PlanningExpertCalendar", {
                                                attrs: {
                                                  isImmaterial:
                                                    _vm.isImmaterial,
                                                  isVES: _vm.isVES,
                                                  isVervolgopname:
                                                    _vm.isVervolgopname,
                                                  isZaakbegeleider:
                                                    _vm.isZaakbegeleider,
                                                  isExpert: _vm.isExpert,
                                                  activeOrganization:
                                                    _vm.activeOrganization,
                                                  planningValidation:
                                                    _vm.planningValidation,
                                                  reservations:
                                                    _vm.reservations,
                                                  currentDate: _vm.selectedDate,
                                                  userType: _vm.userType
                                                },
                                                on: {
                                                  closeDialog: _vm.closeDialog,
                                                  validatePlanning:
                                                    _vm.validatePlanning,
                                                  dateChanged: function(
                                                    $event
                                                  ) {
                                                    return _vm.handleDateChanged(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isZaakbegeleider
            ? _c(
                "v-layout",
                { attrs: { wrap: "", "mt-5": "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "elementPanel elementPanel--shadowed",
                          attrs: {
                            wrap: "",
                            "mb-2": "",
                            "pa-3": "",
                            "px-3": "",
                            "justify-start": ""
                          }
                        },
                        [
                          _c("PlanningExpertCalendar", {
                            attrs: {
                              isImmaterial: _vm.isImmaterial,
                              isVES: _vm.isVES,
                              isVervolgopname: _vm.isVervolgopname,
                              isZaakbegeleider: _vm.isZaakbegeleider,
                              planningValidation: _vm.planningValidation,
                              reservations: _vm.reservations,
                              userType: _vm.userType
                            },
                            on: {
                              closeDialog: _vm.closeDialog,
                              validatePlanning: _vm.validatePlanning
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }