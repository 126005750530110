var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      !_vm.selectedReport
        ? _c(
            "v-container",
            { staticClass: "container--wide" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "planningDialogContainer__header",
                      attrs: { xs12: "" }
                    },
                    [
                      _c(
                        "h2",
                        { staticClass: "elementSubTitle" },
                        [
                          _c("v-icon", [_vm._v("assignment")]),
                          _vm._v(" Dossier ")
                        ],
                        1
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "planningDialogContainer__close",
                          on: {
                            click: function($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [_vm._v("close")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "py-5": "", "px-3": "" } },
                    [
                      _c(
                        "DataTable",
                        {
                          ref: "planningReportDatatable",
                          attrs: {
                            options: _vm.tableOptions,
                            visibility: _vm.visibility,
                            autoRefetch: false
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "filters" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "custom-filter",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.skills,
                                          "item-text": "name",
                                          "item-value": "id",
                                          "hide-details": "",
                                          placeholder: "Competenties",
                                          clearable: "",
                                          multiple: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                var index = ref.index
                                                return [
                                                  index === 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.name) +
                                                            " "
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  index === 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " (+" +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedSkills
                                                                  .length - 1
                                                              ) +
                                                              " andere) "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1005106344
                                        ),
                                        model: {
                                          value: _vm.selectedSkills,
                                          callback: function($$v) {
                                            _vm.selectedSkills = $$v
                                          },
                                          expression: "selectedSkills"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "custom-filter ml-3",
                                      attrs: { shrink: "" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.userLevels,
                                          "item-text": "name",
                                          "item-value": "value",
                                          "hide-details": "",
                                          placeholder: "Niveau",
                                          clearable: "",
                                          multiple: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function(ref) {
                                                var item = ref.item
                                                var index = ref.index
                                                return [
                                                  index === 0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.name) +
                                                            " "
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  index === 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "grey--text caption"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " (+" +
                                                              _vm._s(
                                                                _vm
                                                                  .selectedLevels
                                                                  .length - 1
                                                              ) +
                                                              " andere) "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3998915279
                                        ),
                                        model: {
                                          value: _vm.selectedLevels,
                                          callback: function($$v) {
                                            _vm.selectedLevels = $$v
                                          },
                                          expression: "selectedLevels"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedReport
        ? _c("PlanningReportDetailDialog", {
            attrs: {
              isVES: _vm.isVES,
              planningValidation: _vm.planningValidation,
              report: _vm.selectedReport
            },
            on: {
              closeDialog: _vm.close,
              back: function($event) {
                _vm.selectedReport = null
              },
              chooseReport: _vm.chooseReport
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }