import { Component, Vue, Prop } from 'vue-property-decorator';
import { Settings, DateTime } from 'luxon';
import { Organization } from '@/models/Organization';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import PlanningExpertCalendar from '@/components/planningtool/planning-expert-dialog/PlanningExpertCalendar.vue';
import { Reservation } from '@/models/Reservation';
import { PlanningValidation } from '@/models/PlanningValidation';
import { UserRole } from '@/models/User';

@Component<PlanningExpertDialog>({
  components: {
    PlanningExpertCalendar,
  },
})
export default class PlanningExpertDialog extends Vue {
  @Prop({ default: false })
  protected isZaakbegeleider!: boolean;

  @Prop({ default: false })
  protected isExpert!: boolean;

  @Prop()
  protected planningValidation!: PlanningValidation;

  @Prop({ default: () => [] })
  protected reservations!: Reservation[];

  @Prop({ default: false })
  protected isImmaterial!: boolean;

  @Prop({ default: false })
  protected isVES!: boolean;

  @Prop({ default: false })
  protected isVervolgopname!: boolean;

  @Prop({ default: undefined })
  protected userType!: undefined | UserRole;

  protected organizations: Organization[] | null = null;

  protected isLoading = false;

  protected activeOrganization = '';

  protected selectedDate: DateTime | null = null;

  protected mounted() {
    Settings.defaultLocale = 'nl';

    console.log('PlanningExpertDialog this.isVervolgopname', this.isVervolgopname);

    if (this.planningValidation.organization) {
      this.activeOrganization = this.planningValidation.organization.id as string;
    }

    if (this.planningValidation.report
      && this.planningValidation.report.organization
      // && this.planningValidation.report.isReplanning
    ) {
      this.organizations = [this.planningValidation.report.organization];
      return;
    }

    this.getOrganizations();
  }

  /**
   *
   * Fetches the Orginazations from the API, filter only the Orgainzation of type expert
   * set the activeOrginazation to the first
   *
   * @protected
   * @memberof PlanningExpertDialog
   */
  protected getOrganizations() {
    const filters: {[key: string]: string} = {};
    if (this.isImmaterial) {
      filters.type = 'tcmg';
    } else {
      filters.type = 'expert';
    }

    new Organization()
      .filter(filters)
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (this.organizations.length && ! this.activeOrganization) {
          this.activeOrganization = this.organizations[0].id || '';
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected destroyed() {
    this.$store.dispatch('updateSelectedPlanningDate', '');
  }

  protected closeDialog() {
    this.$emit('closeDialog');
  }

  protected validatePlanning(planningValidation: PlanningValidation) {
    this.$emit('validatePlanning', planningValidation);
  }

  protected handleDateChanged(value: DateTime) {
    this.selectedDate = value;
  }
}
