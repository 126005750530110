import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { PlanningMinute } from '@/models/Tools';
import { PlanningValidation } from '@/models/PlanningValidation';

@Component<PlanningQuestionsDialog>({
  filters: {
    parseHours: (number: number) => {
      if (! number) {
        return '0 uur';
      }

      const hours = (number / 60);
      const roundedHours = Math.floor(hours);
      return `${roundedHours} uur`;
    },
    parseMinutes: (number: number) => {
      if (! number) {
        return '0 minuten';
      }

      const hours = (number / 60);
      const roundedHours = Math.floor(hours);
      const minutes = (hours - roundedHours) * 60;
      const roundedMinutes = Math.round(minutes);
      return `${roundedMinutes} minuten`;
    },
  },
})
export default class PlanningQuestionsDialog extends Vue {
  @Prop()
  protected planningValidation!: PlanningValidation;

  protected minutes: PlanningMinute[] = [];

  protected dynamicFormAppendKeys: string[] = [
    'historicalReport_NulMetingLight',
    'SoortGebouw',
    'Bouwjaar',
    'EigendomSinds',
    'OmschrijvingSchade',
    'WaarIsErSchade',
    'BijzonderhedenAanvraag',
    'GebouwBeschermd',
  ];

  protected mounted() {
    this.createMinutes();
  }

  protected questionAnswered() {
    this.$emit('questionAnswered');
  }

  protected createMinutes() {
    if (! this.planningValidation || ! this.planningValidation.duration) {
      return;
    }

    this.minutes = [];
    Object.keys(this.planningValidation.duration.minutes).forEach((key: string) => {
      if (this.planningValidation.duration.minutes[key]) {
        this.minutes.push(this.planningValidation.duration.minutes[key]);
      }
    });
  }

  protected saveAndClose() {
    this.$emit('durationChanged', 5);
    this.close();
  }

  protected close() {
    this.$emit('closeDialog');
  }

  @Watch('duration')
  protected durationChanged() {
    this.createMinutes();
  }
}
