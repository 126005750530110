var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        [
          _c("v-flex", [
            _c(
              "div",
              {
                staticClass:
                  "planningBlock elementPanel elementPanel--noPadding elementPanel--shadowed",
                class: { disabled: _vm.disabled },
                on: {
                  click: function($event) {
                    return _vm.setFullscreen($event)
                  }
                }
              },
              [
                !_vm.type
                  ? [_vm._v("no type is given")]
                  : [
                      _c("div", {
                        staticClass: "statusBar",
                        class: { completed: _vm.isCompleted }
                      }),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "planningBlock__header",
                              attrs: { xs12: "", "px-3": "", "py-2": "" }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "align-center": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "flex-starving",
                                      attrs: { "pa-0": "" }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "header__icon" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.blockSetting[_vm.type].icon
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "pa-0": "", "ml-1": "" } },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "header__title" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.blockSetting[_vm.type].title
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.topRightLabel
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "pa-0": "",
                                            "ml-1": "",
                                            "text-xs-right": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "header__title" },
                                            [
                                              _c("v-icon", [
                                                _vm._v(_vm._s(_vm.topRightIcon))
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.topRightLabel) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "planningBlock__content planningBlock__minHeight",
                              attrs: { xs12: "", "px-3": "", "py-2": "" }
                            },
                            [
                              !_vm.showContent
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.blockDescription(_vm.type))
                                    )
                                  ])
                                : _vm._t("content")
                            ],
                            2
                          ),
                          _vm.hasFullScreen
                            ? _c(
                                "v-flex",
                                {
                                  staticClass:
                                    "text-xs-right planningBlock__content",
                                  attrs: { xs12: "", "px-3": "", "py-2": "" }
                                },
                                [
                                  _c("v-icon", { staticClass: "fullscreen" }, [
                                    _vm._v("fullscreen")
                                  ])
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }