import { Component, Vue, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { cloneDeep } from 'lodash';
import { PlanningValidation } from '@/models/PlanningValidation';
import { VesStatusEnum, VesStatusLabels } from '@/items/VesStatus';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Rpc } from '@/models/Rpc';
import ErrorHandler from '@/support/ErrorHandler';

@Component<PlanningVesStatusDialog>({
  //
})
export default class PlanningVesStatusDialog extends Vue {
  @Prop()
  protected planningValidation!: PlanningValidation;

  protected report: Report | null = null;

  protected isLoading = true;

  protected async mounted() {
    this.isLoading = true;

    if (this.planningValidation && this.planningValidation.report) {
      this.report = cloneDeep(this.planningValidation.report as Report);
    }

    this.isLoading = false;
  }

  protected closeDialog() {
    this.$emit('closeDialog');
  }

  protected async saveAndClose(status: VesStatusEnum) {
    if (status === VesStatusEnum.UNINTERESTED) {
      this.$store.dispatch('openDialog', this.uninterestedDialogoptions);
    }

    if (status === VesStatusEnum.INTERESTED) {
      this.$store.dispatch('openDialog', this.interestedDialogoptions);
    }
  }

  protected async updateVesStatus(status: VesStatusEnum) {
    const payload = {
      signature: 'planning:ves-decision',
      body: {
        report: this.report?.uuid,
        ves_status: status,
      },
    };

    this.isLoading = true;

    try {
      await new Rpc()
        .rpcPost(payload);

      if (status === VesStatusEnum.UNINTERESTED) {
        const planningValidation = cloneDeep(this.planningValidation) as PlanningValidation;

        if (planningValidation && planningValidation.report) {
          planningValidation.report.ves_status = status;
        }

        this.$emit('validatePlanning', planningValidation);
        this.closeDialog();
        return;
      }

      this.closeDialog();
      this.$emit('vesUpdatedToInterested');
    } catch (error) {
      ErrorHandler.network(error);
    }
  }

  protected get uninterestedDialogoptions(): Options {
    return {
      title: 'VES weigeren',
      text: 'Weet je zeker dat dit dossier definitief niet deelneemt aan de VES regeling?',
      type: 'error',
      buttons: {
        confirm: {
          text: 'Ja',
          action: async () => {
            await this.updateVesStatus(VesStatusEnum.UNINTERESTED);
          },
        },
        cancel: {
          text: 'Nee',
          color: 'text-light',
        },
      },
    };
  }

  protected get interestedDialogoptions(): Options {
    return {
      title: 'Geïnteresseerd in de VES regeling',
      text: 'Wilt u doorgaan? Dit dossier krijgt de status On Hold',
      type: 'error',
      buttons: {
        confirm: {
          text: 'Ja',
          action: async () => {
            await this.updateVesStatus(VesStatusEnum.INTERESTED);
          },
        },
        cancel: {
          text: 'Nee',
          color: 'text-light',
        },
      },
    };
  }

  protected get title(): string {
    return 'VES regeling';
  }

  protected get VesStatusEnum(): typeof VesStatusEnum {
    return VesStatusEnum;
  }

  protected get vesStatusLabels(): {[key: string] :string} {
    return VesStatusLabels;
  }
}
