var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      _c(
        "v-container",
        { staticClass: "container--wide" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "planningDialogContainer__header",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "elementSubTitle" },
                    [
                      _c("v-icon", [_vm._v("date_range")]),
                      _vm._v(" " + _vm._s(_vm.getTitle) + " ")
                    ],
                    1
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "planningDialogContainer__close",
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "mt-5": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "elementPanel elementPanel--shadowed",
                      attrs: {
                        wrap: "",
                        "mb-2": "",
                        "pa-3": "",
                        "px-3": "",
                        "justify-start": ""
                      }
                    },
                    [
                      _vm.isLoading
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "250px"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c(
                              "v-flex",
                              {
                                staticClass: "custom-filter ma-0",
                                attrs: { xs12: "", "text-right": "" }
                              },
                              [
                                _c(
                                  "MiButton",
                                  {
                                    staticClass: "mt-1",
                                    attrs: {
                                      color: "success",
                                      compact: "true",
                                      icon: "save"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.saveAndClose($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Opslaan ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c("h2", { staticClass: "elementSubTitle" }, [
                                  _vm._v("Expertisebureau:")
                                ]),
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm4: "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.organizations,
                                            label: "Kies een Expertisebureau",
                                            "item-text": "name",
                                            "item-value": "id"
                                          },
                                          model: {
                                            value: _vm.selectedOrganization,
                                            callback: function($$v) {
                                              _vm.selectedOrganization = $$v
                                            },
                                            expression: "selectedOrganization"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            !_vm.isLoading
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    !_vm.isOpnameVanAfstand
                                      ? [
                                          _c(
                                            "h2",
                                            { staticClass: "elementSubTitle" },
                                            [_vm._v("Omzetten Opnamevariant:")]
                                          ),
                                          _vm.isZelfopname
                                            ? _c(
                                                "v-layout",
                                                { attrs: { "mt-3": "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { shrink: "" } },
                                                    [
                                                      _c(
                                                        "MiButton",
                                                        {
                                                          attrs: {
                                                            color: "primary"
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.convertRapportType(
                                                                _vm
                                                                  .ReportTypesMap
                                                                  .REGULIER_2021,
                                                                "opname"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Reguliere opname "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { "px-3": "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { wrap: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "subheading primary--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Omzetten naar Reguliere opname"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Dit dossier wordt omgezet naar een reguliere opname"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isZelfopname
                                            ? _c(
                                                "v-layout",
                                                { attrs: { "mt-3": "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { shrink: "" } },
                                                    [
                                                      _c(
                                                        "MiButton",
                                                        {
                                                          attrs: {
                                                            color: "primary"
                                                          },
                                                          nativeOn: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.convertRapportType(
                                                                _vm
                                                                  .ReportTypesMap
                                                                  .REGULIER_2021,
                                                                "zelf_opname"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" Zelfopname ")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { "px-3": "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { wrap: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "subheading primary--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Omzetten naar Zelfopname"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Dit dossier wordt omgezet naar een Zelfopname"
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isDisplayingReportTypeChangeDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isDisplayingReportTypeChangeDialog,
                callback: function($$v) {
                  _vm.isDisplayingReportTypeChangeDialog = $$v
                },
                expression: "isDisplayingReportTypeChangeDialog"
              }
            },
            [
              _c("ReportTypeChangeDialog", {
                attrs: {
                  report: _vm.planningValidation.report,
                  type: _vm.selectedRapportType,
                  opnameVariant: _vm.selectedOpnameVariant,
                  types: _vm.reportTypes
                },
                on: {
                  reload: function($event) {
                    _vm.closeDialog(), _vm.validatePlanning()
                  }
                },
                model: {
                  value: _vm.isDisplayingReportTypeChangeDialog,
                  callback: function($$v) {
                    _vm.isDisplayingReportTypeChangeDialog = $$v
                  },
                  expression: "isDisplayingReportTypeChangeDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }