var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      _c(
        "v-container",
        { staticClass: "container--wide" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "planningDialogContainer__header",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "elementSubTitle" },
                    [
                      _c("v-icon", [_vm._v("settings")]),
                      _vm._v(" Afspraak instellingen ")
                    ],
                    1
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "planningDialogContainer__close",
                      on: {
                        click: function($event) {
                          return _vm.close()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              ),
              !_vm.isLoading
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "mt-5": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--shadowed",
                                  attrs: {
                                    wrap: "",
                                    "mb-2": "",
                                    "pa-3": "",
                                    "px-3": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "custom-filter ma-0",
                                      attrs: { xs12: "", "text-right": "" }
                                    },
                                    [
                                      _c(
                                        "MiButton",
                                        {
                                          staticClass: "mt-1",
                                          attrs: {
                                            color: "success",
                                            compact: "true",
                                            icon: "save"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.saveAndClose($event)
                                            }
                                          }
                                        },
                                        [_vm._v(" Opslaan ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "", sm4: "" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label:
                                                    "Afspraakbevestiging versturen per e-mail aan de aanvrager",
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.confirmEmailToApplicant,
                                                  callback: function($$v) {
                                                    _vm.confirmEmailToApplicant = $$v
                                                  },
                                                  expression:
                                                    "confirmEmailToApplicant"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "", "pt-3": "" } },
                                    [
                                      _c("div", { staticClass: "label" }, [
                                        _vm._v("Tags")
                                      ]),
                                      _c("ReportTags", {
                                        attrs: {
                                          autoSave: false,
                                          reportId: _vm.reportId,
                                          reportTags: _vm.tags,
                                          isEditing: true
                                        },
                                        on: { tagsCreated: _vm.saveReportTags }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reportId && _vm.isImmaterial
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "mt-5": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--shadowed",
                                  attrs: {
                                    wrap: "",
                                    "mb-2": "",
                                    "pa-3": "",
                                    "px-3": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c(
                                      "h2",
                                      { staticClass: "elementSubTitle" },
                                      [_vm._v("Fysieke locaties:")]
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.locations,
                                          disabled:
                                            _vm.isSelectingAddressFromReport,
                                          label: "Adressen",
                                          "item-value": "uuid",
                                          "item-text": "label",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.selectedLocation,
                                          callback: function($$v) {
                                            _vm.selectedLocation = $$v
                                          },
                                          expression: "selectedLocation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.planningValidation &&
                                  _vm.planningValidation.report &&
                                  _vm.planningValidation.report.address
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { "align-center": "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { shrink: "" } },
                                                [
                                                  _c("v-checkbox", {
                                                    staticClass: "ma-0 pa-0",
                                                    attrs: {
                                                      "hide-details": ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    shrink: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "addressLine"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .planningValidation
                                                                              .report
                                                                              .address
                                                                              .street
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .planningValidation
                                                                              .report
                                                                              .address
                                                                              .number
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "addressLine addressLine--small"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .planningValidation
                                                                              .report
                                                                              .address
                                                                              .postcode
                                                                          ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .planningValidation
                                                                              .report
                                                                              .address
                                                                              .city
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1746883642
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.isSelectingAddressFromReport,
                                                      callback: function($$v) {
                                                        _vm.isSelectingAddressFromReport = $$v
                                                      },
                                                      expression:
                                                        "isSelectingAddressFromReport"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reportId
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "mt-5": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--shadowed",
                                  attrs: {
                                    wrap: "",
                                    "mb-2": "",
                                    "pa-3": "",
                                    "px-3": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c(
                                      "h2",
                                      { staticClass: "elementSubTitle" },
                                      [_vm._v("Webex:")]
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("DynamicForm", {
                                        attrs: {
                                          reportId: _vm.reportId,
                                          reportType: _vm.reportType,
                                          planningQuestions: true,
                                          sections: [999],
                                          appendKeys: _vm.dynamicFormAppendKeys,
                                          hasAutosave: true
                                        },
                                        on: {
                                          questionAnswered: _vm.questionAnswered
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.reportId && !_vm.isImmaterial
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "mt-5": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass:
                                    "elementPanel elementPanel--shadowed",
                                  attrs: {
                                    wrap: "",
                                    "mb-2": "",
                                    "pa-3": "",
                                    "px-3": "",
                                    "justify-start": ""
                                  }
                                },
                                [
                                  _c("v-flex", { attrs: { xs12: "" } }, [
                                    _c(
                                      "h2",
                                      { staticClass: "elementSubTitle" },
                                      [_vm._v("Competentie matrix:")]
                                    )
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("Skills", {
                                        ref: "reportSkills",
                                        attrs: {
                                          type: "report",
                                          model: _vm.report,
                                          forceEditable: true,
                                          reportId: _vm.reportId,
                                          disableAutosave: true
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }