import { Component, Prop, Vue } from 'vue-property-decorator';
import { DateTime, Settings } from 'luxon';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Report } from '@/models/Report';
import PlanningBlock from '@/components/planning-block/PlanningBlock.vue';
import PlanningReportDialog from '@/components/planningtool/planning-report-dialog/PlanningReportDialog.vue';
import PlanningExpertDialog from '@/components/planningtool/planning-expert-dialog/PlanningExpertDialog.vue';
import PlanningQuestionsDialog from '@/components/planningtool/planning-questions-dialog/PlanningQuestionsDialog.vue';
import PlanningReportAreaDialog from '@/components/planningtool/planning-report-area-dialog/PlanningReportAreaDialog.vue';
import PlanningZelfopnameDialog from '@/components/planningtool/planning-zelfopname-dialog/PlanningZelfopnameDialog.vue';
import PlanningVesStatusDialog from '@/components/planningtool/planning-ves-status-dialog/PlanningVesStatusDialog.vue';
import PlanningNoAppointmentDialog from '@/components/planningtool/planning-no-appointment-dialog/PlanningNoAppointmentDialog.vue';
import PlanningOnHoldReasonDialog from '@/components/planningtool/planning-on-hold-reason-dialog/PlanningOnHoldReasonDialog.vue';
import PlanningErrorDialog from '@/components/dialog/planning-error-dialog/PlanningErrorDialog.vue';
import PlanningAppointmentDialog from '@/components/planningtool/planning-appointment-dialog/PlanningAppointmentDialog.vue';
import { debounce, sortBy } from 'lodash';
import { PusherService } from '@/support/PusherService';
import { Reservation } from '@/models/Reservation';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import { getAppointmentType } from '@/models/Event';
import { AppointmentSetting, PlanningValidation } from '@/models/PlanningValidation';
import { User, UserRole, userRoles } from '@/models/User';
import ExcludeVesDialog from '@/components/dialog/exclude-ves-dialog/ExcludeVesDialog.vue';
import { NoAppointmentReason } from '@/components/planningtool/planning-no-appointment-dialog/PlanningNoAppointmentDialog';

@Component<PlanningTool>({
  components: {
    PlanningBlock,
    PlanningReportDialog,
    PlanningReportAreaDialog,
    PlanningExpertDialog,
    PlanningQuestionsDialog,
    PlanningAppointmentDialog,
    PlanningNoAppointmentDialog,
    PlanningOnHoldReasonDialog,
    PlanningZelfopnameDialog,
    PlanningVesStatusDialog,
    PlanningErrorDialog,
    ExcludeVesDialog,
  },
  filters: {
    parseTime: (date: string) => {
      if (! date) { return ''; }
      return DateTime.fromSQL(date).toFormat('HH:mm');
    },
    parseDate: (date: string) => {
      if (! date) { return ''; }
      return DateTime.fromSQL(date).toFormat('cccc d LLLL yyyy');
    },
    parseHours: (number: number) => {
      if (! number) {
        return '0 uur 0 minuten';
      }
      const hours = (number / 60);
      const roundedHours = Math.floor(hours);
      const minutes = (hours - roundedHours) * 60;
      const roundedMinutes = Math.round(minutes);
      return `${roundedHours} uur ${roundedMinutes} minuten`;
    },
  },
})
export default class PlanningTool extends Vue {
  public $pageTitle = 'Planning-tool';

  @Prop({ default: false })
  protected isImmaterial!: boolean;

  @Prop({ default: false })
  protected isVES!: boolean;

  protected reservations: Reservation[] = [];

  protected planningValidation: PlanningValidation = new PlanningValidation();

  protected reportsInArea: Report[] = [];

  protected dialogFullscreenMap: {[key:string]: boolean} = {
    report: false,
    datetime: false,
    appointment: false,
    expert: false,
    zelfopname: false,
    zaakbegeleider: false,
    questions: false,
    area: false,
    ves_status: false,
  }

  protected isLoading = true;

  protected isShowingOnHoldReasonDialog = false;

  protected isShowingNoAppointmentdialog = false;

  protected isShowingExcludeVesDialog = false;

  protected isSavingAppointment = false;

  protected isShowingResetPlanningDialog = false;

  protected pusher: PusherService | null = null;

  protected error: AxiosError | null = null;

  protected hasNetworkError = false;

  protected answeredDebounce: Function = this.debounceValidation();

  protected UserRole = UserRole;

  public mounted() {
    Settings.defaultLocale = 'nl';
    this.initialize();
  }

  protected destroyed() {
    if (! this.pusher) {
      return;
    }

    this.pusher.unsubscribe('tcmg');
  }

  protected async initialize() {
    this.isLoading = true;
    this.emitBreadcrumb();
    await this.getReservations();
    if (this.isImmaterial) {
      await this.initPlanningImmaterialValidation();
    } else {
      await this.initPlanningValidation();
    }

    this.initializePusher();
    this.isLoading = false;
  }

  protected async initPlanningValidation() {
    // Set my latest revervation from reservations call
    const myReservation = this.myReservation();

    if (myReservation) {
      this.planningValidation = new PlanningValidation(myReservation);
      this.planningValidation.expert = myReservation.user as User;
    }

    if (this.$route.query.report && ! myReservation) {
      if (this.planningValidation.report && (this.planningValidation.report.uuid !== this.$route.query.report)) {
        this.planningValidation.reset();
      }
      const reportUuid = this.$route.query.report as string;
      this.planningValidation.report = await this.getReport(reportUuid);

      if (this.planningValidation.report) {
        const settings: AppointmentSetting = {
          mediator_appointment_type: this.planningValidation.report.defaultAppointmentTypeZb,
          appointment_type: this.setAppointmentType(),
          confirm_email_to_applicant: this.planningValidation.report.defaultConfirmEmailToApplicant,
        };
        this.planningValidation.settings = settings;
      }
    }

    this.validatePlanning();
    this.getReportsInArea();
  }

  protected setAppointmentType() {
    if (this.planningValidation?.report?.isBezwaarReport) {
      const answer = this.planningValidation.report.printAnswer('BezwaarAmbtelijkOpnameOfHerbeoordeling');
      if (answer === 'Nader advies met opname') {
        return 'second_opinion';
      }
      if (answer === 'Hoorzitting') {
        return 'hoorzitting';
      }
    }

    return this.planningValidation?.report?.defaultAppointmentType || '';
  }

  protected handleVesUpdatedToInterested(): void {
    this.$store.dispatch('openDialog', this.interestedDialogOptions);
  }

  protected async initPlanningImmaterialValidation() {
    const myReservation = this.myReservation();

    if (myReservation) {
      this.planningValidation = new PlanningValidation(myReservation);
      this.planningValidation.user = myReservation.user as User;
    }

    if (this.$route.query.report) {
      if (this.planningValidation.report && (this.planningValidation.report.uuid !== this.$route.query.report)) {
        this.planningValidation.resetImmaterial();
      }
      const reportUuid = this.$route.query.report as string;
      this.planningValidation.report = await this.getReport(reportUuid);

      const settings: AppointmentSetting = {
        mediator_appointment_type: 'physical',
        appointment_type: 'physical',
        confirm_email_to_applicant: this.planningValidation.report.defaultConfirmEmailToApplicant,
      };
      this.planningValidation.settings = settings;
    }

    this.validatePlanning();
  }

  public validatePlanning(planningValidation?: PlanningValidation) {
    const planningValidationModel = planningValidation || this.planningValidation;

    let model = null;
    if (this.isImmaterial) {
      model = planningValidationModel.validateImmaterieel();
    } else if (this.isVES) {
      model = planningValidationModel.validateVes();
    } else {
      model = planningValidationModel.validate();
    }

    if (model === null) { return; }

    model
      .then((response: PlanningValidation) => {
        this.planningValidation = response;

        if (response.report && response.report.uuid) {
          this.planningValidation.report = new Report(response.report);
        }
      })
      .catch((error: AxiosError) => {
        this.$root.$emit('planning:validation.error', planningValidation, true);
        ErrorHandler.network(error);
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Afspraak plannen' },
        ],
      });
  }

  protected async getReservations(reservation?: Reservation) {
    this.reservations = await new Reservation()
      .filter({
        type: this.isImmaterial ? 'ims' : 'regulier',
      })
      .limit(10000)
      .include(['answers', 'address', 'organization', 'tags', 'departure_address', 'skills'])
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (reservation) {
      this.reservations.push(reservation);
    }
  }

  protected myReservation(): Reservation | null {
    const sortedReservations = sortBy(this.reservations, [(reservation) => { reservation.updated_at; }]).reverse();
    const myReservations = sortedReservations.filter((reservation: Reservation) => reservation.reserved_by_user?.uuid && reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid);

    const myReportReservation = myReservations.find((reservation: Reservation) => reservation.report?.uuid === this.$route.query.report);

    return myReportReservation || (this.$route.query.report ? null : (myReservations[0] || null));
  }

  protected initializePusher() {
    this.pusher = new PusherService()
      .author(this.$store.state.Auth)
      .room('tcmg')
      .echo()
      .on('planning:reservation.created', (reservation: Reservation) => {
        if (! reservation || ! reservation.report) { return; }
        this.handleReservationCreated(reservation);
        this.$root.$emit('planning:reservation.created', reservation);
      })
      .on('planning:reservation.released', (reservation: Reservation) => {
        this.handleReservationReleased(reservation);
        this.$root.$emit('planning:reservation.released', reservation);
      })
      .on('pusher:subscription_succeeded', (member: any) => {
      })
      .on('pusher:subscription_error', (response: any) => {
      });
  }

  protected handleReservationCreated(reservation: Reservation) {
    this.getReservations(reservation);
  }

  protected handleReservationReleased(reservation: Reservation) {
    this.reservations = this.reservations.filter((currentReservation: Reservation) => currentReservation.id !== reservation.id);
  }

  protected async resetReservation() {
    await this.deleteReservation();

    if (this.isImmaterial) {
      this.planningValidation.resetImmaterial();
      window.location.href = `${window.location.origin}/planning-tool-immaterieel`;
    } else if (this.isVES) {
      this.planningValidation.reset();
      window.location.href = `${window.location.origin}/planning-tool-ves-nulmeting`;
    } else {
      this.planningValidation.reset();
      window.location.href = `${window.location.origin}/planning-tool`;
    }
  }

  protected async deleteReservation() {
    await new Rpc()
      .rpcPost({
        signature: 'reservations:delete',
        body: {
          user_id: this.$store.state.Auth.uuid,
        },
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected planAppointment() {
    if (this.isSavingAppointment) {
      return;
    }

    this.isSavingAppointment = true;
    const payload = {
      signature: 'report:appointment:create',
      body: {},
    };

    if (this.isImmaterial) {
      payload.signature = 'report:immaterial:appointment:create';
      payload.body = this.planningValidation.payloadImmaterial;
    } else {
      payload.body = this.planningValidation.payload;
    }

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.$store.dispatch('openDialog', this.successDialogOptions);
      })
      .catch((error: any) => {
        this.error = error.response.data;
        this.hasNetworkError = true;
      })
      .finally(() => {
        this.isSavingAppointment = false;
      });
  }

  protected get canSaveAppointment() {
    if (! this.planningValidation || ! this.planningValidation.is_valid) {
      return false;
    }

    const validation = this.planningValidation.is_valid;

    let isValid = true;

    Object.keys(validation).forEach((key: string) => {
      if (! (validation)[key]) {
        isValid = false;
      }
    });

    return isValid;
  }

  protected get successDialogOptions(): Options {
    return {
      title: 'Afspraak succesvol ingepland',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.resetReservation();
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get interestedDialogOptions(): Options {
    return {
      title: 'U bent klaar met dit dossier.',
      text: 'U hoeft verder niets meer te doen.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
            this.resetReservation();
          },
        },
      },
    };
  }

  private async getReport(id: string): Promise<Report> {
    return await new Report()
      .include(['answers', 'departure_address', 'skills', 'organization'])
      .find(id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getReportsInArea() {
    if (! this.planningValidation.report || ! this.planningValidation.report.address) {
      return;
    }
    const reports = await new Report()
      .include(['applicant', 'answers', 'address', 'organization', 'departure_address'])
      .filter(this.planningValidation.report.address.postcode ? { postcodes: [this.planningValidation.report.address.postcode] } : '')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.reportsInArea = reports.filter((report: Report) => report.uuid !== (this.planningValidation.report as any).uuid);
  }

  protected setFullscreen(key: string) {
    if (key === 'report' && this.planningValidation && this.planningValidation.report && this.planningValidation.report.isReplanning) {
      return;
    }

    if (key === 'area' && ! this.reportsInArea.length) {
      return;
    }

    this.dialogFullscreenMap[key] = true;
  }

  protected handleReportSelected(report: Report) {
    if (report.uuid !== (this.planningValidation.report && this.planningValidation.report.uuid)) {
      if (this.isImmaterial) {
        this.planningValidation.resetImmaterial();
      } else {
        this.planningValidation.reset();
      }
    }

    if (report.isReplanning) {
      this.planningValidation.organization = report.organization ? report.organization : null;
    }

    this.planningValidation.report = report;
    this.validatePlanning();

    this.getReportsInArea();
  }

  protected onConfirmOnHoldReason() {
    this.deleteReservation();

    if (this.isVES) {
      this.$router.push('/planning/plannable-reports?planning_status=no_answer&filter=ves');
      return;
    }

    if (this.isImmaterial) {
      this.$router.push('/planning/plannable-reports?planning_status=no_answer&filter=ims');
      return;
    }

    this.$router.push('/planning/plannable-reports?planning_status=no_answer&planning_workflow=standaard&filter=default');
  }

  protected onConfirmNoAppointment() {
    this.deleteReservation();

    if (this.isVES) {
      this.$router.push('/planning/plannable-reports?planning_status=no_answer&filter=ves');
      return;
    }

    if (this.isImmaterial) {
      this.$router.push('/planning/plannable-reports?planning_status=no_answer&filter=ims');
      return;
    }

    this.$router.push('/planning/plannable-reports?planning_status=no_answer&planning_workflow=standaard&filter=default');
  }

  protected debounceValidation() {
    return debounce(() => {
      this.validatePlanning();
    }, 1000);
  }

  // Getters
  protected get hasErrorsOrWarnings() {
    if (! this.planningValidation) {
      return false;
    }

    return this.planningValidation.hasErrors || this.planningValidation.hasWarnings;
  }

  protected get isOpnameVanAfstand() {
    if (! this.planningValidation.report) {
      return false;
    }

    return this.planningValidation.report.isOpnameVanAfstandReport || this.planningValidation.settings?.appointment_type === 'opname_op_afstand';
  }

  protected get isZelfopname() {
    if (! this.planningValidation.report) {
      return false;
    }

    return this.planningValidation.report.isZelfopnameReport;
  }

  protected get isReplanning() {
    if (! this.planningValidation || ! this.planningValidation.report) {
      return false;
    }

    return this.planningValidation.report.isReplanning;
  }

  protected get isManager() {
    return this.$store.state.Auth.hasRole(userRoles.ManagerRoles);
  }

  protected get departureAddress() {
    return this.planningValidation?.encodeDepartureAddress || '';
  }

  protected get mediatorDepartureAddress() {
    return this.planningValidation?.encodeMediatorDepartureAddress || '';
  }

  protected get appointmentType() {
    if (this.isImmaterial && ! this.planningValidation.settings?.appointment_type) {
      return getAppointmentType('physical');
    }

    return getAppointmentType(this.planningValidation.settings?.appointment_type);
  }

  protected get mediatorAppointmentType() {
    if (this.isImmaterial && ! this.planningValidation.settings?.mediator_appointment_type) {
      return getAppointmentType('physical');
    }

    return getAppointmentType(this.planningValidation.settings?.mediator_appointment_type);
  }

  protected get destinationAddress() {
    return new Report(this.planningValidation?.report as Report).encodedReportAddress || '';
  }

  protected get isVervolgopname() {
    return new Report(this.planningValidation?.report as Report).hasPlanningStatus('vervolgopname') || false;
  }

  protected get datetimeBlockType(): string {
    if (this.isImmaterial) {
      return 'datetimeImmaterieel';
    }

    if (this.isVES) {
      return 'datetimeOpnemer';
    }

    return 'datetimeexpert';
  }

  protected get planningTitle() {
    if (! this.planningValidation.report) { return 'Afspraak inplannen'; }

    if (this.isVervolgopname) {
      return 'Vervolgopname inplannen';
    }

    if (new Report(this.planningValidation.report).hasPlanningStatus('nader_onderzoek')) {
      return 'Naderonderzoek inplannen';
    }

    if (new Report(this.planningValidation.report).hasPlanningStatus('aanvullend_onderzoek')) {
      return 'Aanvullend onderzoek inplannen';
    }

    if (this.isImmaterial) {
      return 'Afspraak inplannen Immaterieel';
    }

    return 'Afspraak inplannen';
  }

  protected get noAppointmentReasons(): NoAppointmentReason[] {
    if (this.isVES) {
      return [
        {
          label: 'Geen gehoor',
          value: 'no_answer',
        },
        {
          label: 'Terugbel afspraak',
          value: 'callback',
        },
      ];
    }

    return [
      {
        label: 'Geen gehoor',
        value: 'no_answer',
      },
      {
        label: 'Terugbel afspraak',
        value: 'callback',
      },
      {
        label: 'Niet meer nodig',
        value: 'discarded',
      },
    ];
  }
}

export interface PlanningValidationPayload {
  report?: string;
  expert?: string;
  mediator?: string;
  organization?: string;
  starts_at?: string;
  ends_at?: string;
  mediator_starts_at?: string;
  mediator_ends_at?: string;
  without_mediator?: boolean;
}
