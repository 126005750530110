var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        { staticClass: "error-dialog" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "text-xs-center": "" } },
                      [
                        _c(
                          "div",
                          { staticClass: "error-icon" },
                          [_c("v-icon", [_vm._v("warning")])],
                          1
                        )
                      ]
                    ),
                    _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                      _c("h2", { staticClass: "dialog-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.selectedError && _vm.selectedError.message
                                ? _vm.selectedError.message
                                : "Planning Error"
                            ) +
                            " "
                        )
                      ])
                    ])
                  ],
                  _vm.selectedError && !_vm.isLoading
                    ? [
                        _vm._l(_vm.errors, function(error, index) {
                          return _c(
                            "v-flex",
                            {
                              key: index,
                              staticClass: "panel-container",
                              attrs: { xs12: "" }
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: { wrap: "", row: "", "align-top": "" }
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", grow: "" } },
                                    [
                                      _c(
                                        "v-expansion-panel",
                                        {
                                          staticClass: "panel",
                                          attrs: { expand: "" },
                                          model: {
                                            value: _vm.panels[index],
                                            callback: function($$v) {
                                              _vm.$set(_vm.panels, index, $$v)
                                            },
                                            expression: "panels[index]"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-expansion-panel-content",
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "header" },
                                                  slot: "header"
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        wrap: "",
                                                        "align-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _vm._v(
                                                            _vm._s(error.name) +
                                                              " heeft (" +
                                                              _vm._s(
                                                                error.content
                                                                  .length
                                                              ) +
                                                              ") errors"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-card",
                                                { staticClass: "panel__inner" },
                                                [
                                                  [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs12: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "ol",
                                                                  _vm._l(
                                                                    error.content,
                                                                    function(
                                                                      content,
                                                                      contentIndex
                                                                    ) {
                                                                      return _c(
                                                                        "li",
                                                                        {
                                                                          key: contentIndex
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                content
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "v-flex",
                          { attrs: { x12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        "Totaal: " + _vm._s(_vm.totalErrors)
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { "align-center": "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "text-xs-center": "" } },
                                  [
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: { color: "success" },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.close($event)
                                          }
                                        }
                                      },
                                      [_vm._v("Ok")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }