import { PlanningValidation } from '@/models/PlanningValidation';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Report, Answer } from '@/models/Report';
import { getStatusColor, getStatusLabel } from '@/support/ReportStatus';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import ReportAppointment from '@/components/report-appointment/ReportAppointment.vue';
import ReportAppointmentCommunication from '@/components/report-appointment-communication/ReportAppointmentCommunication.vue';
import { HistoricalReport } from '@/models/HistoricalReport';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { Panel } from '@/views/Reports/Report';
import HistoricalDamagePanel from '@/components/historical-damage-panel/HistoricalDamagePanel.vue';
import { Options } from '@/components/mi-dialog/MiDialog';
import { PusherService } from '@/support/PusherService';
import { Reservation } from '@/models/Reservation';

@Component<PlanningReportDetailDialog>({
  components: {
    ReportAppointment,
    ReportAppointmentCommunication,
    HistoricalDamagePanel,
  },
})
export default class PlanningReportDetailDialog extends Vue {
  @Prop()
  protected report!: Report;

  @Prop({ default: () => {} })
  protected planningValidation!: PlanningValidation;

  @Prop({ default: false })
  protected isVES!: boolean;

  protected historicalReports: HistoricalReport[] = [];

  protected panelHistoricalReport: Panel[] | null = [];

  protected isOpenPanels = false;

  protected pusher: PusherService | null = null;

  protected mediatorDepartureAddress = '';

  protected destinationAddress = '';

  protected departureAddress = '';

  protected generalKeys: GeneralKey = GeneralKeys;

  protected mounted() {
    if (! this.report) {
      return;
    }

    this.$root.$on('planning:reservation.created', this.checkReservation);

    this.setGeneralInfo();
    this.getHistoricalReports();
  }

  protected destroyed() {
    this.$root.$off('planning:reservation.created');
  }

  protected checkReservation(reservation: Reservation) {
    if (! reservation.report) {
      return;
    }

    if (reservation && reservation.reserved_by_user && reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid) {
      return;
    }

    reservation.report.uuid === this.report.uuid ? this.$store.dispatch('openDialog', this.dialogOptions) : null;
  }

  protected getHistoricalReports() {
    if (! this.report) {
      return;
    }

    new HistoricalReport()
      .include(['files'])
      .filter({ report: this.report.uuid })
      .all()
      .then((historicalReports: HistoricalReport[]) => {
        this.historicalReports = historicalReports;
        this.openAllHistoricReports();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected openAllHistoricReports() {
    this.panelHistoricalReport = [...Array((this.historicalReports as any).length).keys()].map((_) => [true]);
    this.isOpenPanels = true;
  }

  // Reset the panel
  protected closeAllHistoricReports() {
    this.panelHistoricalReport = [];
    this.isOpenPanels = false;
  }

  protected close() {
    this.$emit('closeDialog');
  }

  protected chooseReport() {
    this.$emit('chooseReport', this.report);
    this.close();
  }

  protected back() {
    this.$emit('back');
  }

  protected getStatusColor(key: string) {
    return getStatusColor(key);
  }

  protected getStatusLabel(key: string) {
    return getStatusLabel(key);
  }

  protected setGeneralInfo() {
    for (const key in this.generalKeys) {
      this.generalKeys[key].value = '';
    }

    const keys = Object.keys(this.generalKeys);
    if (! this.report || ! this.report.answers) {
      return;
    }

    this.report.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as GeneralKey)[anwser.key ? anwser.key : ''].value = anwser?.value || '';
      }
    });
  }

  protected getVatText() {
    if (this.report && this.report.calculation_model_vat_low) {
      return `met ${this.report.calculation_model_vat_low}% BTW`;
    }
    return '';
  }

  protected get dialogOptions(): Options {
    return {
      title: 'Dossier al geselecteerd',
      text: 'Je kunt dit dossier niet meer kiezen, omdat dit dossier inmiddels door iemand anders is geselecteerd.',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Sluiten',
          action: () => {
            this.$emit('back');
          },
        },
      },
    };
  }

  @Watch('panelHistoricalReport')
  protected panelsChanged() {
    if (! this.panelHistoricalReport) {
      return;
    }

    if ((this.historicalReports as HistoricalReport[]).length === this.panelHistoricalReport.length) {
      const panelDamages = this.panelHistoricalReport.filter((item: Panel) => item[0] === true);

      if (panelDamages.length === (this.historicalReports as HistoricalReport[]).length) {
        this.isOpenPanels = true;
      } else {
        this.isOpenPanels = false;
      }
    }
  }
}
