import { Component, Vue, Prop } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { Tag } from '@/models/Tag';
import { TableVisibility } from '@/components/data-table/DataTable';
import { getStatusColor, getStatusLabel } from '@/support/ReportStatus';
import { Address } from '@/models/Address';
import { DateTime } from 'luxon';

@Component<PlanningReportAreaDialog>({})
export default class PlanningReportAreaDialog extends Vue {
  @Prop()
  protected reportPostcode !: string;

  protected report: Report | null = null;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
    // FIXME: set back to true - Stefano: false is only for demo
    pagination: false,
  };

  protected deadlineIcons: any = {
    approved: require('@/assets/images/icons/request-accepted.svg'),
    pending: require('@/assets/images/icons/request-pending.svg'),
    rejected: require('@/assets/images/icons/request-rejected.svg'),
  }

  protected mounted() {
    //
  }

  protected close() {
    this.$emit('closeDialog');
  }

  protected get tableOptions(): Object {
    return {
      model: new Report().include('organization').filter({ postcodes: [this.reportPostcode] }),
      headers: [
        {
          text: 'Zaaknummer',
          value: 'case_number',
          action: '/reports/{id}',
          sortable: {
            key: 'case_number',
            order: 'ASC',
          },
          transform: (case_number: string, report: any) => {
            if (! case_number) {
              return '';
            }
            if (report.is_prio) return `<i aria-hidden="true" class="v-icon material-icons theme--light isPrio">warning</i> ${case_number}`;
            return case_number;
          },
          width: '10%',
        },
        {
          text: 'Indieningsdatum',
          value: 'applicant_submitted_at',
          sortable: {
            key: 'applicant_submitted_at',
            order: 'DESC',
          },
          transform: (applicant_submitted_at: string, report: Report) => this.parseApplicantSubmittedAt(applicant_submitted_at),
        },
        {
          hide: false,
          text: 'Bureau',
          value: 'organization',
          transform: (organization: any) => {
            if (! organization) {
              return '-';
            }
            return organization.name;
          },
          sortable: {
            key: 'organization',
            order: 'ASC',
          },
          width: '15%',
        },
        {
          hide: false,
          text: 'Opnemer',
          value: 'expert',
          transform: (expert: any) => {
            if (! expert) {
              return '-';
            }
            return expert.name;
          },
          sortable: {
            key: 'expert',
            order: 'ASC',
          },
          width: '15%',
        },
        {
          text: 'Adres',
          value: 'address',
          transform: (address: any) => this.parseAddress(address),
          width: '15%',
        },
        {
          text: 'Status',
          value: 'status',
          width: '10%',
          transform: (status: string) => {
            if (! status) {
              return '';
            }
            return `<span class="statusPill statusBackgroundColor--${getStatusColor(status)}">${getStatusLabel(status)}</span>`;
          },
          sortable: {
            key: 'status',
            order: 'ASC',
          },
        },
        {
          text: 'Tags',
          value: 'tags',
          transform: (tags: Tag[]) => {
            let tagNames = '';

            if (! tags) {
              return tagNames;
            }

            tags.forEach((tag: Tag, index: number) => {
              index == 0 ? tagNames += `${tag.name}` : tagNames += `, ${tag.name}`;
            });
            return tagNames;
          },
        },
        {
          text: 'Type rapport',
          value: 'type',
          transform: (report_type: any) => report_type.name,
          sortable: {
            key: 'type',
            order: 'ASC',
          },
        },
      ],
      filter: [],
    };
  }

  protected parseApplicantSubmittedAt(date: string) {
    if (! date) {
      return '-';
    }
    return DateTime.fromFormat(date, 'yyyy-LL-dd').toFormat('dd-LL-yyyy');
  }

  protected parseAddress(address: Address) {
    if (! address) {
      return '';
    }

    if (! address.street && ! address.number && ! address.number_add && ! address.postcode && ! address.city) {
      return '';
    }

    return `${address.street} ${address.number} ${address.number_add} <br>
            ${address.postcode} ${address.city}`;
  }

  protected parseDeadline(deadline_at: string, report: Report) {
    if (deadline_at === '0000-00-00 00:00:00') {
      return '';
    }

    const deadlineDate = DateTime.fromFormat(deadline_at, 'yyyy-LL-dd HH:mm:ss');
    const currentDate = DateTime.local();
    const difference = deadlineDate.diff(currentDate, 'days').toObject().days;
    const deadlineNumber = Math.floor((difference as any));

    let deadlineIcon = '';
    let deadlineNumberClass = '';
    let deadlineNumberType = '';

    if (report && report.last_late_request_status) {
      deadlineIcon = `<span><img class="deadlineIcon" src="${this.deadlineIcons[report.last_late_request_status]}"></span>`;
    }

    if (deadlineNumber < 0) {
      deadlineNumberClass = 'negativeNumber';
    } else if (deadlineNumber >= 0 && deadlineNumber < 5) {
      deadlineNumberClass = 'warningNumber';
      deadlineNumberType = '+';
    } else {
      deadlineNumberClass = 'positiveNumber';
      deadlineNumberType = '+';
    }

    return `<span class="deadlineNumber ${deadlineNumberClass}">${deadlineNumberType}${deadlineNumber}</span> ${deadlineIcon}`;
  }
}
