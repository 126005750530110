import { ReportTypes } from '@/support/ReportTypes';
import { PlanningValidation } from '@/models/PlanningValidation';
import { cloneDeep } from 'lodash';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import { Tag } from '@/models/Tag';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import PlanningReportDetailDialog from '@/components/planningtool/planning-report-detail-dialog/PlanningReportDetailDialog.vue';
import { formatDate } from '@/support/String';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Reservation } from '@/models/Reservation';
import { Skill } from '@/models/Skill';
import { UserLevelItem, userLevelLabels, UserLevels } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<PlanningReportDialog>({
  components: {
    PlanningReportDetailDialog,
  },
})
export default class PlanningReportDialog extends Vue {
  @Prop()
  protected planningValidation!: PlanningValidation;

  @Prop({ default: () => [] })
  protected reservations!: Reservation[];

  @Prop({ default: false })
  protected isImmaterial!: boolean;

  @Prop({ default: false })
  protected isVES!: boolean;

  protected selectedReport: Report | null = null;

  protected isShowingReportDetailDialog = false;

  protected reservedReports: Report[] = [];

  protected skills: Skill[] = [];

  protected selectedSkills: Skill[] | null = null;

  protected selectedLevels: UserLevelItem[] | null = null;

  protected userLevels: UserLevelItem[] = UserLevels;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected mounted() {
    this.getSkills();

    if (this.planningValidation.report && this.planningValidation.report.uuid) {
      this.selectedReport = cloneDeep(this.planningValidation.report);
    }
  }

  protected close() {
    this.$emit('closeDialog');
    this.selectedReport = null;
  }

  protected chooseReport(report: Report) {
    this.$emit('itemSelected', report);
    this.close();
  }

  protected getSkills() {
    new Skill()
      .all()
      .then((skills: Skill[]) => {
        this.skills = skills;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private get reportFilters() {
    const filters: {[key: string]: any} = {};
    const currentUserReservation = this.reservations.find((reservation: Reservation) => reservation.reserved_by_user && reservation.reserved_by_user.uuid && reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid);

    if (currentUserReservation) {
      filters.reservation = currentUserReservation.id;
    }

    if (this.selectedSkills && this.selectedSkills.length) {
      filters.skills = this.selectedSkills;
    }

    if (this.selectedLevels && this.selectedLevels.length) {
      filters.levels = this.selectedLevels;
    }

    if (this.planningValidation.organization && this.planningValidation.expert && this.planningValidation.ends_at) {
      filters.planning = {
        user: this.planningValidation.expert.uuid,
        organization: this.planningValidation.organization.id,
        datetime: this.planningValidation.ends_at,
      };
    }

    if (this.isImmaterial) {
      filters.type = ReportTypes.IMMATERIELESCHADE;
    } else if (this.isVES) {
      filters.type = ReportTypes.VES_NULMETING;
    } else {
      filters.exclude_types = [ReportTypes.IMMATERIELESCHADE, ReportTypes.VES_NULMETING];
    }

    filters.planning_status = 'unplanned';
    filters.in_project = false; // Exclude reports within a Job/Continuous project
    return filters;
  }

  protected get datatableModel() {
    return new Report()
      .include(['applicant', 'answers', 'skills', 'address'])
      .sort('planning', 'ASC')
      .filter(this.reportFilters);
  }

  protected get tableOptions(): Object {
    return {
      model: this.datatableModel,
      headers: [
        {
          text: '',
          value: '',
          width: '10px',
          transform: (dataTable: DataTable, report: Report) => {
            let htmlString = '';

            if (this.isReservedReport(report)) {
              htmlString = '<span class="reserved"></span>';
            }

            if (this.isSelectedReport(report)) {
              htmlString = '<span class="selected"></span>';
            }
            return htmlString;
          },
        },
        {
          text: 'Zaaknummer',
          value: 'case_number',
          action: (report: Report) => {
            if (! this.isReservedReport(report)) {
              this.selectedReport = report;
              this.isShowingReportDetailDialog = true;
            }
          },
          class: 'action--span',
        },
        {
          text: 'Tags',
          value: 'tags',
          transform: (tags: Tag[]) => {
            let tagNames = '';

            if (! tags) {
              return tagNames;
            }

            tags.forEach((tag: Tag, index: number) => {
              index == 0 ? tagNames += `${tag.name}` : tagNames += `, ${tag.name}`;
            });
            return tagNames;
          },
        },
        {
          text: 'Competenties',
          value: 'skills',
          transform: (skills: Skill[]) => {
            let skillNames = '';

            if (! skills) {
              return skillNames;
            }

            skills.forEach((skill: Skill, index: number) => {
              index == 0 ? skillNames += `${skill.name}` : skillNames += `, ${skill.name}`;
            });
            return skillNames;
          },
        },
        {
          text: 'Type rapport',
          value: 'type',
          transform: (report_type: any) => report_type.name,
        },
        {
          text: 'Niveau',
          value: 'level',
          transform: (level: any) => (userLevelLabels[level] ? userLevelLabels[level] : '-'),
        },
        {
          text: 'Indieningsdatum',
          value: 'applicant_submitted_at',
          transform: (applicant_submitted_at: any) => {
            if (applicant_submitted_at === '0000-00-00') {
              return '-';
            }
            return formatDate(applicant_submitted_at, 'dd-LL-yyyy');
          },
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: (report: Report) => {
            let icon = 'remove_red_eye';

            if (this.isReservedReport(report)) {
              icon = 'lock';
            }

            if (this.isSelectedReport(report)) {
              icon = 'check_circle';
            }

            return icon;
          },
          action: (dataTable: DataTable, report: Report) => {
            if (! this.isReservedReport(report)) {
              this.selectedReport = report;
              this.isShowingReportDetailDialog = true;
            }
          },
          tooltip: (report: Report) => (this.isReservedReport(report) ? 'Dit dossier is al geselecteerd' : 'Bekijk de details van dit dossier'),
        },
      ],
      filter: [],
    };
  }

  protected isSelectedReport(selectedReport: Report) {
    return this.reservations.some((reservation: Reservation) => {
      if (! reservation.report || ! reservation.report.uuid || ! reservation.reserved_by_user || ! reservation.reserved_by_user.uuid) {
        return false;
      }
      return reservation.report.uuid === selectedReport.uuid && reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid;
    });
  }

  protected isReservedReport(selectedReport: Report) {
    return this.reservations.some((reservation: Reservation) => {
      if (! reservation.report || ! reservation.report.uuid || ! reservation.reserved_by_user || ! reservation.reserved_by_user.uuid) {
        return false;
      }

      return reservation.report.uuid === selectedReport.uuid && reservation.reserved_by_user.uuid !== this.$store.state.Auth.uuid;
    });
  }

  protected get dialogOptions(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je voor dit dossier een afspraak wilt maken?',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Afspraak inplannen',
          action: () => {
            this.$emit('itemSelected', this.selectedReport);
            this.close();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedReport = null;
          },
        },
      },
    };
  }

  protected refreshDataTable() {
    const datatable = this.$refs.planningReportDatatable as DataTable;
    if (! datatable) { return; }

    datatable.setModel(this.datatableModel);
  }

  @Watch('selectedSkills')
  protected selectedSkillsChanged() {
    this.refreshDataTable();
  }

  @Watch('selectedLevels')
  protected selectedLevelsChanged() {
    this.refreshDataTable();
  }
}
