import { VesStatusEnum } from '@/items/VesStatus';
import { PlanningValidation } from '@/models/PlanningValidation';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<PlanningBlock>({})
export default class PlanningBlock extends Vue {
  @Prop()
  protected type!: string;

  @Prop({ default: true })
  protected hasFullScreen!: boolean;

  @Prop({ default: false })
  protected isCompleted!: boolean;

  @Prop({ default: false })
  protected showContent!: boolean;

  @Prop({ default: '' })
  protected topRightIcon!: string;

  @Prop({ default: '' })
  protected topRightLabel!: string;

  @Prop({ default: false })
  protected isZelfopname!: boolean;

  @Prop({ default: false })
  protected isOpnameVanAfstand!: boolean;

  @Prop({ default: false })
  protected disabled!: boolean;

  @Prop({ default: false })
  protected hasReportClick!: boolean;

  // New Way:
  @Prop()
  protected planningValidation!: PlanningValidation;

  // Old Way:
  @Prop()
  protected reportId!: string;

  public mounted() {
    //
  }

  protected initialize() {
    //
  }

  protected setFullscreen(event: MouseEvent) {
    if (this.disabled) {
      return;
    }
    if (! this.hasReportClick) {
      this.$emit('fullscreen', true);
      return;
    }

    const target = event.target as Node;
    if (! target) {
      this.$emit('fullscreen', true);
      return;
    }

    const parent = target.parentElement;
    if (parent && parent.classList.contains('summaryItem__report')) {
      if (this.planningValidation.report) {
        // New Way
        window.open(`${window.location.origin}/reports/${this.planningValidation.report.uuid}`);
      } else {
        // Old Way
        window.open(`${window.location.origin}/reports/${this.reportId}`);
      }
    } else {
      this.$emit('fullscreen', true);
    }
  }

  protected blockDescription(key: string) {
    if (this.isZelfopname) {
      if (key === 'datetime' || key === 'expert') {
        return 'N.v.t. bij Zelfopname';
      }
    }

    if (! this.isZelfopname && key === 'zelfopname') {
      return 'Alleen van toepassing bij Zelfopname';
    }

    return this.blockSetting[key].default;
  }

  protected get blockSetting(): BlockSetting {
    return {
      report: {
        icon: 'assignment',
        title: 'Dossier',
        default: 'Je hebt nog geen dossier geselecteerd. Klik op dit blok om een dossier te kiezen.',
      },
      datetimeexpert: {
        icon: 'date_range',
        title: 'Deskundige, datum en tijd',
        default: 'Je hebt nog geen deskundige, datum en tijd voor de afspraak geselecteerd. Klik op dit blok om een datum en tijd voor de afspraak te kiezen.',
      },
      datetimeOpnemer: {
        icon: 'date_range',
        title: 'Opnemer, datum en tijd',
        default: 'Je hebt nog geen opnemer, datum en tijd voor de afspraak geselecteerd. Klik op dit blok om een datum en tijd voor de afspraak te kiezen.',
      },
      datetimeImmaterieel: {
        icon: 'date_range',
        title: 'Medewerker',
        default: 'Je hebt nog geen medewerker, datum en tijd voor de afspraak geselecteerd. Klik op dit blok om een datum en tijd voor de afspraak te kiezen.',
      },
      datetime: {
        icon: 'date_range',
        title: 'Datum en Tijd',
        default: 'Je hebt nog geen datum en tijd voor de afspraak geselecteerd. Klik op dit blok om een datum en tijd voor de afspraak te kiezen.',
      },
      expert: {
        icon: 'assignment_ind',
        title: 'Deskundige',
        default: 'Je hebt nog geen expert geselecteerd. Klik op dit blok om een expert voor de afspraak te kiezen.',
      },
      appointment: {
        icon: 'settings',
        title: 'Afspraak',
        default: 'Kies een dossier om de afspraak soort te kiezen',
      },
      ves_status: {
        icon: 'payments',
        title: 'VES regeling',
        default: this.vesBlockDescription,
      },
      zelfopname: {
        icon: 'assignment_ind',
        title: this.isOpnameVanAfstand ? 'Opname op Afstand' : 'Zelfopname',
        default: 'Je hebt nog geen bureau en zaakbegeleider geselecteerd.',
      },
      zaakbegeleider: {
        icon: 'face',
        title: 'Zaakbegeleider',
        default: 'Je hebt nog geen zaakbegeleider geselecteerd. Klik op dit blok om een zaakbegeleider te kiezen.',
      },
      questions: {
        icon: 'question_answer',
        title: 'Vragen en Calculatieduur',
        default: 'Je hebt nog geen dossier geselecteerd. Selecteer eerst een dossier om de calculatieduur te berekenen.',
      },
      area: {
        icon: 'public',
        title: 'Dossiers met dezelfde postcode',
        default: 'Je hebt nog geen dossier geselecteerd. Selecteer eerst een dossier om de dossiers van dezelfde postcode te zien.',
      },
      departure_address: {
        icon: 'public',
        title: 'Route Deskundige',
        default: 'Je hebt nog geen dossier geselecteerd. Selecteer eerst een dossier om de vertrekadres van de deskundige te zien.',
      },
      opnemer_departure_address: {
        icon: 'public',
        title: 'Route Opnemer',
        default: 'Je hebt nog geen dossier geselecteerd. Selecteer eerst een dossier om de vertrekadres van de opnemer te zien.',
      },
      mediator_departure_address: {
        icon: 'public',
        title: 'Route Zaakbegeleider',
        default: 'Je hebt nog geen dossier geselecteerd. Selecteer eerst een dossier om de vertrekadres van de zaakbegeleider te zien.',
      },
      uitvraag: {
        icon: 'question_answer',
        title: 'Aangevraagd door bureau',
        default: '',
      },
    };
  }

  protected get vesBlockDescription(): string {
    if (this.planningValidation?.report?.ves_status === VesStatusEnum.UNSUITED) {
      return 'Dit dossier is niet geschikt voor de VES regeling.';
    }

    if (this.planningValidation?.report?.ves_status === VesStatusEnum.INTERESTED) {
      return 'Aanvrager is geïnteresseerd in de VES regeling.';
    }

    if (this.planningValidation?.report?.ves_status === VesStatusEnum.UNINTERESTED) {
      return 'Aanvrager heeft de VES regeling geweigerd.';
    }

    if (this.planningValidation?.report?.ves_status === VesStatusEnum.EXCLUDED) {
      return 'Dossier is uitgesloten van de VES regeling.';
    }

    return 'Is de aanvrager geïnteresseerd in de VES regeling?';
  }
}

interface BlockSetting {
  [key: string]: {
    icon?: string;
    title?: string;
    default?: string;
  }
}
