import { RejectReason } from '@/models/RejectReason';
import ReportTypeChangeDialog from '@/components/dialog/report-type-change-dialog/ReportTypeChangeDialog.vue';
import { ReportTypes as ReportTypesMap } from '@/support/ReportTypes';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Settings } from 'luxon';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import '@fullcalendar/list/main.min.css';

import { Organization } from '@/models/Organization';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

import { Report } from '@/models/Report';
import { Reservation } from '@/models/Reservation';
import { ReportType } from '@/models/ReportType';
import { cloneDeep } from 'lodash';
import { PlanningValidation } from '@/models/PlanningValidation';

@Component<PlanningZelfopnameDialog>({
  components: {
    ReportTypeChangeDialog,
  },
})
export default class PlanningZelfopnameDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected reportClone: Report | null = null;

  @Prop()
  protected planningValidation!: PlanningValidation;

  @Prop({ default: false })
  protected isOpnameVanAfstand!: boolean;

  // @Prop()
  // protected duration!: PlanningDuration;

  // @Prop()
  // protected expert!: string;

  // @Prop()
  // protected selectedDate!: string;

  // @Prop()
  // protected organization!: string;

  // @Prop()
  // protected mediator!: string;

  // @Prop({default: false})
  // protected isZaakbegeleider!: boolean;

  // @Prop({default: false})
  // protected withoutCaseMediator!: boolean;

  @Prop({ default: () => [] })
  protected reservations!: Reservation[];

  protected organizations: Organization[] | null = null;

  protected selectedOrganization = '';

  protected reportTypes: ReportType[] | null = null;

  protected selectedRapportType = '';

  protected selectedOpnameVariant = '';

  protected isLoading = true;

  protected rejectReasons: RejectReason[] = [];

  protected selectedRejectReason = '';

  protected rejectReasonComment = '';

  protected isDisplayingReportTypeChangeDialog = false;

  // protected planningValidation: Tools | null = null;

  protected async mounted() {
    this.isLoading = true;
    Settings.defaultLocale = 'nl';

    if (this.planningValidation && this.planningValidation.report) {
      this.reportClone = cloneDeep(this.planningValidation.report as Report);
    }

    this.selectedOrganization = cloneDeep((this.planningValidation?.organization as Organization).id as string);
    await this.getOrganizations();
    await this.getReportTypes();
    this.isLoading = false;
  }

  /**
   *
   * Fetches the Orginazations from the API, filter only the Orgainzation of type expert
   * set the activeOrginazation to the first
   *
   * @protected
   * @memberof PlanningExpertDialog
   */
  protected async getOrganizations() {
    this.organizations = await new Organization()
      .getAllExperts()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected async getReportTypes() {
    this.reportTypes = await new ReportType()
      .filter({
        is_creatable: true,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected destroyed() {
    this.$store.dispatch('updateSelectedPlanningDate', '');
  }

  protected closeDialog() {
    this.$emit('itemSelected', this.reportClone);
    this.$emit('closeDialog');
  }

  protected async saveAndClose() {
    // update call voor organizations
    // await this.updateOrganization();
    // this.$emit('eventChanged', {
    //   organization: this.selectedOrganization
    // });
    await this.updateReportType();

    const planningValidation = cloneDeep(this.planningValidation);
    planningValidation.organization = new Organization({ id: this.selectedOrganization });

    this.$emit('validatePlanning', planningValidation);
    this.closeDialog();
  }

  protected validatePlanning() {
    this.$emit('validatePlanning');
  }

  // protected async updateOrganization() {
  //   if (!this.reportClone) {
  //     return;
  //   }

  //   const planningValidation = cloneDeep(this.planningValidation);
  //   planningValidation.organization = new Organization({id: this.selectedOrganization});

  //   this.$emit('validatePlanning', planningValidation);
  // }

  protected async updateReportType() {
    if (! this.reportClone || ! this.planningValidation || ! this.planningValidation.report) {
      return;
    }

    const payload = {
      report_type: this.reportClone.type && this.reportClone.type.uuid ? this.reportClone.type.uuid : this.reportClone.type,
    };

    await new Report(this.reportClone)
      .convertType(this.planningValidation.report.uuid, payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected convertRapportType(type: string, opnameVariant: string) {
    this.selectedRapportType = type;
    this.selectedOpnameVariant = opnameVariant;
    this.isDisplayingReportTypeChangeDialog = true;
  }

  protected get isZelfopname() {
    return this.planningValidation.report?.isZelfopnameReport;
  }

  protected get ReportTypesMap() {
    return ReportTypesMap;
  }

  protected get getTitle(): string {
    return this.isOpnameVanAfstand ? 'Opname op Afstand' : 'Zelfopname';
  }
}
