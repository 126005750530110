var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "error-icon text-center" },
              [
                _c("v-icon", { staticClass: "alert--text" }, [
                  _vm._v("warning")
                ])
              ],
              1
            ),
            _vm._v(" " + _vm._s(_vm.opnameVariantName) + " ")
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { xs12: "" } }, [
                          _c("p", [
                            _vm._v(
                              "Weet je zeker dat je de opname variant wilt veranderen naar " +
                                _vm._s(_vm.opnameVariantName) +
                                "?"
                            )
                          ])
                        ]),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                placeholder: "Reden van wijziging",
                                items: _vm.rejectReasons,
                                "item-text": "name",
                                "return-object": ""
                              },
                              model: {
                                value: _vm.selectedRejectReason,
                                callback: function($$v) {
                                  _vm.selectedRejectReason = $$v
                                },
                                expression: "selectedRejectReason"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          !_vm.isLoading
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fadedText link mr-3",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: {
                                  disabled: !_vm.selectedRejectReason,
                                  color: "primary",
                                  small: "true"
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.submit($event)
                                  }
                                }
                              },
                              [_vm._v(" aanpassen ")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }