import { Report } from '@/models/Report';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
import { OnHoldReason, OnHoldReasonUuidEnum, PlanningOnHoldReasonRpcPayload } from '@/models/OnHoldReason';
import { rules } from '@/support/ValidationRules';

@Component<PlanningOnHoldReasonDialog>({})
export default class PlanningOnHoldReasonDialog extends Vue {
  // #region @Props

  @Prop()
  protected report!: Report;

  // #endregion

  // #region Props

  protected rules = rules;

  protected isSavingOnHoldReason = false;

  protected onHoldReason: null | OnHoldReasonUuidEnum = null;

  protected onHoldReasons: OnHoldReason[] = [];

  // #endregion

  // #region Handlers

  protected onClose(): void {
    this.$emit('input', false);
  }

  // #endregion

  // #region Helpers

  protected getOnHoldReasonRpcPayload(): null | PlanningOnHoldReasonRpcPayload {
    if (! this.report?.uuid || ! this.onHoldReason) { return null; }

    return {
      signature: 'planning:on-hold-reason',
      body: {
        report: this.report.uuid,
        on_hold_reason: this.onHoldReason,
      },
    };
  }

  // #endregion

  // #region API requests

  protected async getOnHoldReasons(): Promise<void> {
    if (this.onHoldReasons.length > 0) { return; }

    const onHoldReasons: OnHoldReason[] = await new OnHoldReason()
      .filter({ selectable: true })
      .limit(500)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    this.onHoldReasons = onHoldReasons;
  }

  protected async onSaveOnHoldReason(): Promise<void> {
    this.isSavingOnHoldReason = true;

    const onHoldReasonRpcPayload: null | PlanningOnHoldReasonRpcPayload = this.getOnHoldReasonRpcPayload();

    if (! onHoldReasonRpcPayload) { return; }

    const onHoldReasonRpcResponse = await new Rpc()
      .rpcPost(onHoldReasonRpcPayload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (onHoldReasonRpcResponse) {
      this.$emit('on-hold-reason-saved');
    }

    this.isSavingOnHoldReason = false;
  }

  // #endregion

  // #region Getters & Setters

  protected get canSaveOnHoldReason(): boolean {
    return ! this.isSavingOnHoldReason && !! this.report?.uuid && !! this.onHoldReason;
  }

  // #endregion
}
