import { Address } from '@/models/Address';
import { Rpc } from '@/models/Rpc';
import { PlanningValidation } from '@/models/PlanningValidation';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { appointmentTypeMap, AppointmentTypeMap } from '@/models/Event';
import { Report, Tag } from '@/models/Report';
import { cloneDeep } from 'lodash';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import Skills from '@/components/skills/Skills';

@Component<PlanningAppointmentDialog>({})
export default class PlanningAppointmentDialog extends Vue {
  @Prop()
  protected planningValidation!: PlanningValidation;

  @Prop({ default: false })
  protected isImmaterial!: boolean;

  protected appointmentTypes: AppointmentTypeMap[] = appointmentTypeMap;

  protected selectedAppointmentType = 'default';

  protected confirmEmailToApplicant = true;

  protected dynamicFormAppendKeys: string[] = [
    'WebexLink',
    'WebexAccessCode',
  ];

  protected tags: Tag[] = [];

  protected selectedTags: Tag[] = [];

  private isLoading = true;

  private isUpdating = false;

  protected hasSubmission = false;

  protected locations = [];

  protected selectedLocation = '';

  protected isSelectingAddressFromReport = false;

  protected async mounted() {
    this.isLoading = true;
    this.tags = this.planningValidation.report?.tags ? cloneDeep(this.planningValidation.report?.tags) : [];

    if (this.isImmaterial) {
      // this.selectedAppointmentType = 'physical';
      this.isSelectingAddressFromReport = (this.planningValidation.address as Address)?.uuid === this.planningValidation.report?.address?.uuid || false;
      this.selectedLocation = (this.planningValidation?.address as Address)?.uuid || '';
    }

    if (this.planningValidation.settings) {
      this.confirmEmailToApplicant = this.planningValidation.settings?.confirm_email_to_applicant;
    }

    await this.fetchAddresses();

    this.isLoading = false;
  }

  protected async saveAndClose(): Promise<void> {
    this.$emit('durationChanged', 5);
    if (this.planningValidation.settings?.appointment_type) {
      this.planningValidation.settings.confirm_email_to_applicant = this.confirmEmailToApplicant;
    }

    this.planningValidation.address_from_report = this.isSelectingAddressFromReport;

    this.planningValidation.address = this.selectedLocation;

    await Promise.all([
      this.updateReport(),
      this.updateReportSkills(),
    ]);
    this.$emit('validatePlanning');

    this.close();
  }

  protected async fetchAddresses() {
    const response = await new Rpc()
      .rpcPost({ signature: 'planning:immaterial:address:get', body: {} }, false);

    if (response) {
      this.locations = response.data;
    }
  }

  protected saveReportTags(tags: Tag[]) {
    this.selectedTags = tags;
  }

  protected updateReport() {
    this.isUpdating = true;

    new Report()
      .convertTags(this.reportId, {
        tags: this.selectedTags,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isUpdating = false;
      });
  }

  protected async updateReportSkills(): Promise<void> {
    if (this.$refs.reportSkills) {
      (this.$refs.reportSkills as Skills).updateLevel();
      (this.$refs.reportSkills as Skills).addSkills();
    }
  }

  protected questionAnswered() {
    this.$emit('questionAnswered');
  }

  protected close() {
    this.$emit('closeDialog');
  }

  protected get report(): Report | null {
    return this.planningValidation.report;
  }

  protected get reportId() {
    return this.planningValidation.report?.uuid;
  }

  protected get reportType() {
    return this.planningValidation.report?.type?.uuid;
  }
}
