import { AxiosError } from 'axios';
import { User } from '@/models/User';
import ErrorHandler from '@/support/ErrorHandler';
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { Rpc } from '@/models/Rpc';
import { Options } from '@/components/mi-dialog/MiDialog';
import { Report } from '@/models/Report';
import { ReportTypes } from '@/support/ReportTypes';
import { debounce } from 'lodash';
import WorkFlowFilter from '@/components/filters/work-flow-filter/WorkFlowFilter';
import OpnameVariantFilter from '@/components/filters/opname-variant-filter/OpnameVariantFilter';
import { rules } from '@/support/ValidationRules';
import { PlanningValidation } from '@/models/PlanningValidation';

@Component<ExcludeVesDialog>({})
export default class ExcludeVesDialog extends Vue {
  @Prop({ default: null })
  protected planningValidation!: PlanningValidation;

  protected isLoading = false;

  protected rules = rules;

  protected text = '';

  protected close() {
    this.$emit('input', false);
  }

  protected async send() {
    if (! this.text || ! this.planningValidation?.report?.uuid) {
      console.log('send return ');
      return;
    }

    this.isLoading = true;

    const payload: {[key: string]: any} = {
      signature: 'planning:ves-decision',
      body: { report: this.planningValidation?.report?.uuid,
        ves_status: 'excluded',
        ves_reason: this.text },
    };

    const response = await new Rpc()
      .rpcPost(payload);

    this.$emit('onCompleted', this.planningValidation);

    // FIXME
    setTimeout(() => {
      this.isLoading = false;
      this.close();
    }, 2000);
  }

  protected get isDisabled() {
    return (this.text?.length > 128) || ! this.text;
  }
}
