import { Component, Vue, Prop } from 'vue-property-decorator';
import { cloneDeep } from 'lodash';

@Component<PlanningErrorDialog>({})
export default class PlanningErrorDialog extends Vue {
  @Prop()
  protected error!: {[key: string]: any};

  protected isLoading = false;

  protected selectedError: {[key: string]: string} | null = null;

  protected errors: {[key: string]: string}[] = [];

  protected panels: boolean[] = [];

  protected mounted() {
    this.selectedError = cloneDeep(this.error);
    if (! this.selectedError || ! this.selectedError.errors) {
      return;
    }

    Object.keys(this.selectedError.errors).forEach((name: string) => {
      this.errors.push({
        name: name === 'expert' ? 'Deskundige' : 'Zaakbegeleider',
        content: this.selectedError ? this.selectedError.errors[name as any] : '',
      });
    });
  }

  protected get totalErrors() {
    let total = 0;
    this.errors.forEach((error: {[key: string]: string}) => {
      total += error.content.length;
    });
    return total;
  }

  protected close() {
    this.$emit('input', false);
    this.$emit('close');
  }
}
