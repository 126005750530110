var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "planningDialogContainer" },
    [
      _c("div", { staticClass: "dialogBar" }),
      _c(
        "v-container",
        { staticClass: "container--wide" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  staticClass: "planningDialogContainer__header",
                  attrs: { xs12: "" }
                },
                [
                  _c(
                    "h2",
                    { staticClass: "elementSubTitle" },
                    [
                      _c("v-icon", [_vm._v("payments")]),
                      _vm._v(" " + _vm._s(_vm.title) + " ")
                    ],
                    1
                  ),
                  _c(
                    "v-icon",
                    {
                      staticClass: "planningDialogContainer__close",
                      on: {
                        click: function($event) {
                          return _vm.closeDialog()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "mt-5": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "elementPanel elementPanel--shadowed",
                      attrs: {
                        wrap: "",
                        "mb-2": "",
                        "pa-3": "",
                        "px-3": "",
                        "justify-start": ""
                      }
                    },
                    [
                      _vm.isLoading
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("LoaderCard", {
                                attrs: {
                                  flat: "",
                                  type: "spinner--center",
                                  minHeight: "250px"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _vm.report.ves_status ===
                                _vm.VesStatusEnum.SUITED
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-w-1/2 tw-flex tw-flex-wrap tw-justify-between"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tw-w-full tw-text-lg tw-mb-16"
                                            },
                                            [
                                              _vm._v(
                                                " Is de aanvrager van dit dossier geïnteresseerd in de VES regeling? "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "warning",
                                                icon: "close"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.saveAndClose(
                                                    _vm.VesStatusEnum
                                                      .UNINTERESTED
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.vesStatusLabels[
                                                      _vm.VesStatusEnum
                                                        .UNINTERESTED
                                                    ]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "MiButton",
                                            {
                                              attrs: {
                                                color: "success",
                                                icon: "check"
                                              },
                                              nativeOn: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.saveAndClose(
                                                    _vm.VesStatusEnum.INTERESTED
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.vesStatusLabels[
                                                      _vm.VesStatusEnum
                                                        .INTERESTED
                                                    ]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.report.ves_status ===
                                _vm.VesStatusEnum.UNSUITED
                                  ? [
                                      _vm._v(
                                        " Dit dossier is niet geschikt voor de VES regeling. "
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.report.ves_status ===
                                _vm.VesStatusEnum.UNINTERESTED
                                  ? [
                                      _vm._v(
                                        " Aanvrager heeft de VES regeling geweigerd. "
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.report.ves_status ===
                                _vm.VesStatusEnum.INTERESTED
                                  ? [
                                      _vm._v(
                                        " Aanvrager is geïnteresseerd in de VES regeling. "
                                      )
                                    ]
                                  : _vm._e(),
                                _vm.report.ves_status ===
                                _vm.VesStatusEnum.EXCLUDED
                                  ? [
                                      _vm._v(
                                        " Dossier is uitgesloten van de VES regeling. "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }