var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          !_vm.isLoading
            ? _c("template", { slot: "header" }, [
                _c("h2", { staticClass: "dialog-title" }, [
                  _vm._v("Uitsluiten van VES")
                ])
              ])
            : _vm._e(),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "250px"
                    }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-space-between": "",
                        "align-baseline": "",
                        "mb-4": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            ref: "textAreaRef",
                            attrs: {
                              placeholder:
                                "Geef een reden voor de uitsluiting van de VES regeling",
                              rules: [
                                _vm.rules.required,
                                _vm.rules.maxLength128
                              ]
                            },
                            model: {
                              value: _vm.text,
                              callback: function($$v) {
                                _vm.text = $$v
                              },
                              expression: "text"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("template", { slot: "actions" }, [
            !_vm.isLoading
              ? _c(
                  "div",
                  { staticClass: "tw-flex tw-justify-between" },
                  [
                    _c(
                      "MiButton",
                      {
                        staticClass: "ml-1",
                        attrs: { color: "warning", small: "true" },
                        nativeOn: {
                          click: function($event) {
                            return _vm.close($event)
                          }
                        }
                      },
                      [_vm._v("Annuleren")]
                    ),
                    _c(
                      "MiButton",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          color: "success",
                          disabled: _vm.isDisabled,
                          small: "true"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.send($event)
                          }
                        }
                      },
                      [_vm._v(" Bevestig ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }